import { event } from "jquery";

const initQuestionnaireBuilder = () => {
    if(document.querySelector('[js-activator^="questionnaire_builder"]')) {
        init_questionnaire_builder_actions_navbar();
        loadQuestions()
        init_question_selector();
        initSaver()
    } 
}

// Changer le contenu d'une question quand le type est modifié ===============================================================
function active_question_type_eventlistener(active_question, type_selector) {
    let question_content = active_question.getElementsByClassName('questionnaire_builder_forms_question_contenu_question')[0];
    type_selector.addEventListener('change', (event) => {
        question_editor_type_update(question_content, type_selector)
        switch (type_selector.value) {
            case 'Choix Multiples': 
                add_awnser_choice()
                break
            case 'Cases à cocher': 
                add_awnser_choice()
                break
            case 'Liste déroulante': 
                add_awnser_choice()
                break
            case 'Grille à choix multiples': 
                add_awnser_choice()
                break
            case 'Grille case à cocher': 
                add_awnser_choice()
                break
        }
    });
}


// INITIALISATION ============================================================================================================
function initFirstQuestion() {
    document.getElementsByClassName('questionnaire_builder_forms_content')[0].appendChild(new_question_html());
    let selected_question = document.getElementsByClassName('selected_question')[0];
    active_question_trash_eventlistener(selected_question, selected_question.getElementsByClassName('removeQuestion')[0])
    active_question_show_options_eventlistener(selected_question, selected_question.getElementsByClassName('showConditions')[0])
    active_question_redirect_options_eventlistener(selected_question, selected_question.getElementsByClassName('redirectToSection')[0])

}

function init_questionnaire_builder_actions_navbar() {
    let html_builder_navbar =   `<div class="questionnaire_navbar">
                                    <div class="questionnaire_builder_saver_navbar">
                                        <div class="content">
                                            <div class="save_statut unsaved">
                                                <div class="save_icon"><i class="fas fa-save"></i></div>
                                                <div class="save_text">Non sauvegardé</div>
                                            </div>
                                            <a class="save_btn"><i class="fas fa-sync"></i></a>
                                        </div>
                                    </div>
                                    <div class="questionnaire_builder_navbar">
                                        <div class="content">
                                            <a class="add_question"><i class="fas fa-plus-square"></i></a>
                                            <a class="add_title"><i class="fas fa-heading"></i></a>
                                            <a class="add_section"><i class="fas fa-grip-lines"></i></a>
                                        </div>
                                    </div>
                                </div>`
    document.getElementsByClassName('page_main')[0].innerHTML += html_builder_navbar;
    let builder_navbar = document.getElementsByClassName("questionnaire_builder_navbar")[0].getElementsByClassName("content")[0];
    let add_question_button = builder_navbar.getElementsByClassName("add_question")[0];
    let add_title_button = builder_navbar.getElementsByClassName("add_title")[0];
    let add_section_button = builder_navbar.getElementsByClassName("add_section")[0];
    add_question_button.addEventListener("click", (event) => {
        add_new_question();
    });
    add_title_button.addEventListener("click", (event) => {
        add_new_title();
    });
    add_section_button.addEventListener("click", (event) => {
        add_new_section();
    });
}

function init_question_selector() {
    var questions = document.getElementsByClassName('questionnaire_builder_forms_content')[0].children;
    for(let question of questions) {
        if(question.classList.contains("selected_question") === false) {
            question.addEventListener('click', (event) => {
                selectQuestion(question);
            })
        } else {
            active_question_type_eventlistener(question, question.getElementsByClassName('type_question_selector')[0])
        }
    }
}
// NAVBAR ====================================================================================================================
function add_new_question() {
    let selected_question = document.getElementsByClassName('selected_question')[0];
    if(selected_question !== undefined) {
        selected_question.after(new_question_html())
        selected_question.classList.remove('selected_question')
        unselectQuestionHide();
        reset_question_selector();
    } else {
        let new_selected = new_question_html()
        document.getElementsByClassName('questionnaire_builder_forms_content')[0].appendChild(new_selected)
        selectQuestion(new_selected);
    }
}

function add_new_title() {
    let selected_question = document.getElementsByClassName('selected_question')[0];
    if(selected_question !== undefined) {
        selected_question.after(new_title_html())
        selected_question.classList.remove('selected_question')
        unselectQuestionHide();
        reset_question_selector();
    } else {
        let new_selected = new_title_html()
        document.getElementsByClassName('questionnaire_builder_forms_content')[0].appendChild(new_selected)
        selectQuestion(new_selected);
    }
}

function add_new_section() {
    let selected_question = document.getElementsByClassName('selected_question')[0];
    if(selected_question !== undefined) {
        selected_question.after(new_section_html())
        selected_question.classList.remove('selected_question')
        unselectQuestionHide();
        reset_question_selector();
    } else {
        let new_selected = new_section_html()
        document.getElementsByClassName('questionnaire_builder_forms_content')[0].appendChild(new_selected)
        selectQuestion(new_selected);
    }
}

// Selected Question FUNCTIONS ================================================================================================
function question_editor_type_update(question_content, question_type) {
    let html_question_content = ""
    switch (question_type.value) {
        case 'Texte Court':
            html_question_content = `
                <div class="question_texte_court">
                    <div class="question_lignes">
                        <input type="text" minlength="1" placeholder="Réponse courte" disabled>
                    </div>
                </div>
            `;
            break
        case 'Texte Long':
            html_question_content = `
                <div class="question_texte_long">
                    <div class="question_lignes">
                        <textarea type="text" minlength="1" placeholder="Réponse longue" disabled></textarea>
                    </div>
                </div>
            `;
            break
        case 'Choix Multiples':
            html_question_content = `
                <div class="question_choix_multiples">
                    <div class="question_lignes">
                        <fieldset>
                            <input type="text" placeholder="réponse">
                            <input type="text" placeholder="réponse">
                            <div class="addRmvBtn">
                                <a class="addInput"><i class="fas fa-plus-circle"></i></a>
                                <a class="removeInput"><i class="fas fa-minus-circle"></i></a>
                            </div>
                        </fieldset>
                    </div>
                </div>
            `;
            break
        case 'Cases à cocher':
            html_question_content = `
                <div class="question_cases_a_cocher">
                    <div class="question_lignes">
                        <fieldset>
                            <input type="text" placeholder="réponse">
                            <input type="text" placeholder="réponse">
                            <div class="addRmvBtn">
                                <a class="addInput"><i class="fas fa-plus-circle"></i></a>
                                <a class="removeInput"><i class="fas fa-minus-circle"></i></a>
                            </div>                    
                        </fieldset>
                    </div>
                </div>
            `;
            break
        case 'Liste déroulante':
            html_question_content = `
                <div class="question_liste_deroulante">
                    <div class="question_lignes">
                        <fieldset>
                            <input type="text" placeholder="réponse">
                            <input type="text" placeholder="réponse">
                            <div class="addRmvBtn">
                                <a class="addInput"><i class="fas fa-plus-circle"></i></a>
                                <a class="removeInput"><i class="fas fa-minus-circle"></i></a>
                            </div>                    
                        </fieldset>
                    </div>
                </div>
            `;
            break
        case 'Echelle linéaire':
            html_question_content = `
                <div class="question_texte_court">
                    <div class="question_lignes">
                        <input type="number" minlength="1" placeholder="Minimum">
                        <input type="number" minlength="1" placeholder="Maximum">
                    </div>
                </div>
            `;
            break
        case 'Grille à choix multiples':
            html_question_content = `
                <div class="question_grille_choix_multiples">
                    <div class="question_grille_lignes">
                        <fieldset>
                            <input type="text" placeholder="ligne">
                            <input type="text" placeholder="ligne">
                            <div class="addRmvBtn">
                                <a class="addInput"><i class="fas fa-plus-circle"></i></a>
                                <a class="removeInput"><i class="fas fa-minus-circle"></i></a>
                            </div>                    
                        </fieldset>
                    </div>
                    <div class="question_grille_colonnes">
                        <fieldset>
                            <input type="text" placeholder="colonne">
                            <input type="text" placeholder="colonne">
                            <div class="addRmvBtn">
                                <a class="addInput"><i class="fas fa-plus-circle"></i></a>
                                <a class="removeInput"><i class="fas fa-minus-circle"></i></a>
                            </div>                    
                        </fieldset>
                    </div>
                </div>
            `;
            break
        case 'Grille case à cocher':
            html_question_content = `
                <div class="question_grille_cases_a_cocher">
                    <div class="question_grille_lignes">
                        <fieldset>
                            <input type="text" placeholder="ligne">
                            <input type="text" placeholder="ligne">
                            <div class="addRmvBtn">
                                <a class="addInput"><i class="fas fa-plus-circle"></i></a>
                                <a class="removeInput"><i class="fas fa-minus-circle"></i></a>
                            </div>                    
                        </fieldset>
                    </div>
                    <div class="question_grille_colonnes">
                        <fieldset>
                            <input type="text" placeholder="colonne">
                            <input type="text" placeholder="colonne">
                            <div class="addRmvBtn">
                                <a class="addInput"><i class="fas fa-plus-circle"></i></a>
                                <a class="removeInput"><i class="fas fa-minus-circle"></i></a>
                            </div>
                        </fieldset>
                    </div>
                </div>
            `;
            break
        case 'Date':
            html_question_content = `
                <div class="question_texte_court">
                    <div class="question_lignes">
                        <input type="date" placeholder="${Date.today}" disabled>
                    </div>
                </div>
            `;
            break
        case 'Nombre':
            html_question_content = `
                <div class="question_texte_court">
                    <div class="question_lignes">
                        <input type="number" placeholder="0" disabled>
                    </div>
                </div>
            `;
            break
        default:
            html_question_content = `
            `;
            break
    }
    question_content.innerHTML = html_question_content
    return question_content
}

function add_awnser_choice() {
    let actions_buttons = document.getElementsByClassName('addRmvBtn');
    for(let actions_button of actions_buttons) {
        actions_button.getElementsByClassName('addInput')[0].addEventListener('click', (event) => {
            var new_element = actions_button.previousElementSibling.cloneNode();
            new_element.setAttribute("name", `${id_generator(10)}`);
            actions_button.before(new_element);
        })
        actions_button.getElementsByClassName('removeInput')[0].addEventListener('click', (event) => {
            var previous_element = actions_button.previousElementSibling;
            if(actions_button.parentNode.children.length > 2) {
                actions_button.parentNode.removeChild(previous_element)
            }
        })
    }

}

// Selecteur de question ======================================================================================================
function selectQuestion(new_selected_question) {
    let not_question_types = ['titre', 'section'];
    let old_selected_question = document.getElementsByClassName('selected_question')[0];
    if(not_question_types.includes(old_selected_question.querySelector('select#types-questions').value) === false) {
        let old_question_options = old_selected_question.getElementsByClassName('questionnaire_builder_forms_question_options_show')[0]
        if(old_question_options.style.display === 'flex') {
            old_selected_question.getElementsByClassName('showConditions')[0].click()
        }
    }
    if(old_selected_question === undefined){
        new_selected_question = document.getElementsByClassName('questionnaire_builder_forms_question')[0];
    } else {
        old_selected_question.classList.remove("selected_question");
    }
    new_selected_question.classList.add("selected_question");
    unselectQuestionHide();
    setSelected(new_selected_question);
    reset_question_selector();
}

function unselectQuestionHide() {
    let questions = document.getElementsByClassName('questionnaire_builder_forms_content')[0].children;
    for(let question of questions) {
        if(question.classList.contains("selected_question") === false) {
            setUnselected(question)
        }
    }
}

function setUnselected(question) {
    question.getElementsByClassName('questionnaire_builder_forms_question_main')[0].style.display = "none";
    question.getElementsByClassName('questionnaire_builder_forms_question_titre')[0].children[1].setAttribute("disabled", "")
    question.getElementsByClassName('questionnaire_builder_forms_question_type_question')[0].children[1].setAttribute("disabled", "")
    question.getElementsByClassName('questionnaire_builder_forms_question_footer')[0].style.display = "none";
}

function setSelected(new_selected_question) {
    new_selected_question.getElementsByClassName('questionnaire_builder_forms_question_main')[0].style.display = "flex";
    new_selected_question.getElementsByClassName('questionnaire_builder_forms_question_titre')[0].children[1].removeAttribute("disabled");
    new_selected_question.getElementsByClassName('questionnaire_builder_forms_question_type_question')[0].children[1].removeAttribute("disabled");
    new_selected_question.getElementsByClassName('questionnaire_builder_forms_question_footer')[0].style.display = "flex";
}

function reset_question_selector() {
    var before_cloning_questions = document.getElementsByClassName('questionnaire_builder_forms_content')[0].children;
    eventListenerRemoverAll(before_cloning_questions)
    var questions = document.getElementsByClassName('questionnaire_builder_forms_content')[0].children;
    for(let question of questions) {
        if(question.classList.contains("selected_question") === false) {
            question.addEventListener('click', (event) => {
                selectQuestion(question);
            })
        } else {
            active_question_type_eventlistener(question, question.getElementsByClassName('type_question_selector')[0]);
            active_question_trash_eventlistener(question, question.getElementsByClassName('removeQuestion')[0]);
            add_awnser_choice();
            let not_question_types = ['titre', 'section'];
            if(not_question_types.includes(question.querySelector('select#types-questions').value) === false) {
                active_question_show_options_eventlistener(question, question.getElementsByClassName('showConditions')[0]);
                active_question_redirect_options_eventlistener(question, question.getElementsByClassName('redirectToSection')[0]);
            }

        }
    }
    return questions
}

// Save =======================================================================================================================
function initSaver() {
    let save_statut = document.getElementsByClassName('save_statut')[0];
    setUnsaved(save_statut);
    let save_btn = document.querySelector('body > div.page_main.options > div.questionnaire_navbar > div.questionnaire_builder_saver_navbar > div > a');
    save_btn.addEventListener('click', (event) => {
        save_questions();
        setSaved(save_statut);
    });
    document.addEventListener('keydown', (event) => {
        setUnsaved(save_statut);
    });
}

function setSaved(save_statut) {
    save_statut.classList.remove('unsaved')
    save_statut.classList.add('saved')
    save_statut.querySelector('.save_text').innerText = "Sauvegardé"
    let save_form = document.querySelector('.data_saver_questionnaires_builder').parentElement;
    save_form.submit();
}

function setUnsaved(save_statut) {
    save_statut.classList.remove('saved')
    save_statut.classList.add('unsaved')
    save_statut.querySelector('.save_text').innerText = "Non sauvegardé"
}

function save_questions() {
    let line_types = ['Choix Multiples', 'Cases à cocher', 'Liste déroulante', 'Texte Court', 'Texte Long', 'Nombre', 'Date', 'Echelle linéaire'];
    let grille_types = ['Grille à choix multiples', 'Grille case à cocher'];
    let not_question_types = ['titre', 'section'];
    let questions = document.querySelectorAll('.questionnaire_builder_forms_question');
    let saver_array = [];
    for(let question of questions){
        let save_qtn = {};
        let question_head = question.querySelector('.questionnaire_builder_forms_question_head');
        let question_content = question.querySelector('.questionnaire_builder_forms_question_main');
        let question_options = question.querySelector('.questionnaire_builder_forms_question_options');
        let question_footer = question.querySelector('.questionnaire_builder_forms_question_footer');
        // HEAD SAVE
        save_qtn.titre = question_head.querySelector('input#question-titre').value.replace(/ /g, "_");
        save_qtn.type = question_head.querySelector('select#types-questions').value.replace(/ /g, "_");
        // CONTENT SAVE
        if(line_types.includes(question_head.querySelector('select#types-questions').value)) {
            let question_ligne_inputs = question.querySelectorAll('.question_lignes input');
            let lignes_values = [];
            for(let input of question_ligne_inputs) {
                lignes_values.push(input.value.replace(/ /g, "_"));
            }
            save_qtn.lignes = lignes_values;
        } else if (grille_types.includes(question_head.querySelector('select#types-questions').value)) {
            let question_grille_ligne_inputs = question_content.querySelectorAll('.question_grille_lignes input');
            let question_grille_colonnes_inputs = question_content.querySelectorAll('.question_grille_colonnes input');
            let lignes_values = [];
            let colonnes_values = [];
            for(let input of question_grille_ligne_inputs) {
                lignes_values.push(input.value.replace(/ /g, "_"));
            }
            for(let input of question_grille_colonnes_inputs) {
                colonnes_values.push(input.value.replace(/ /g, "_"));
            }
            save_qtn.lignes = lignes_values;
            save_qtn.colonnes = colonnes_values;
        }
        // OPTIONS SAVE
        if(not_question_types.includes(question_head.querySelector('select#types-questions').value) === false) {
            let show_option = question_options.querySelector('.questionnaire_builder_forms_question_options_show');
            let redirect_option = question_options.querySelector('.questionnaire_builder_forms_question_options_redirect');
            let show_option_save = [];
            let redirect_option_save = [];
            for(let options_critere of show_option.querySelectorAll('.options_critere')) {
                let obj = {};
                obj.condition = options_critere.querySelector('#option-critere-condition').value.replace(/ /g, "_");
                obj.question = options_critere.querySelector('#option-critere-question').value.replace(/ /g, "_");
                obj.mode = options_critere.querySelector('#option-critere-mode').value.replace(/ /g, "_");
                obj.reponses = Array.from(options_critere.querySelector('.option_critere_show_value').options).map(option => option.value.replace(/ /g, "_"));
                show_option_save.push(obj);
            }
            for(let options_critere of redirect_option.querySelectorAll('.options_critere')) {
                let obj = {};
                obj.mode = options_critere.querySelector('#option-critere-mode').value.replace(/ /g, "_");
                obj.reponses = Array.from(options_critere.querySelector('.option_critere_redirect_value').options).map(option => option.value.replace(/ /g, "_"));
                obj.redirection = options_critere.querySelector('#option-critere-redirection').value.replace(/ /g, "_");
                redirect_option_save.push(obj);
            }
            save_qtn.show_options = show_option_save;
            save_qtn.redirect_options = redirect_option_save;
        }
        // FOOTER SAVE
        if(question.querySelector('.question_obligatoire') !== null) {
            save_qtn.obligatoire = question_footer.querySelector('.question_obligatoire').checked.toString().replace(/ /g, "_");
        }
        // SAVE QTN
        saver_array.push(save_qtn);
    }
    let save_input = document.querySelector('.data_saver_questionnaires_builder');
    save_input.value = JSON.stringify(saver_array);
}

// Load =======================================================================================================================
function loadQuestions() {
    let questions_data = document.querySelector(".data_saver_questionnaires_builder").value;
    let questions_data_parsed = JSON.parse(questions_data);
    let question_types = ['Choix Multiples', 'Cases à cocher', 'Liste déroulante', 'Texte Court', 'Texte Long', 'Nombre', 'Date', 'Echelle linéaire', 'Grille à choix multiples', 'Grille case à cocher'];
    if(questions_data_parsed.length > 0) {
        for(let question of questions_data_parsed) {
            if(question_types.includes(question.type.replace(/[_]+/g, " "))){
                loadQuestion(question);
            } else if (question.type === 'titre') {
                loadHead(question);
            } else if (question.type === 'section') {
                loadSection(question);
            }
        }
    } else {
        initFirstQuestion();
    }
}

function loadQuestion(question) {
    document.getElementsByClassName('questionnaire_builder_forms_content')[0].appendChild(load_question_html(question));
    let selected_question = document.getElementsByClassName('selected_question')[0];
    selected_question = setValuesWhileLoadingQuestions(question, selected_question);
    selectQuestion = setOptionsWhileLoadingQuestions(question, selected_question);
    active_question_trash_eventlistener(selected_question, selected_question.getElementsByClassName('removeQuestion')[0])
    active_question_show_options_eventlistener(selected_question, selected_question.getElementsByClassName('showConditions')[0])
    active_question_redirect_options_eventlistener(selected_question, selected_question.getElementsByClassName('redirectToSection')[0])
}

function loadHead(question) {

}

function loadSection(question) {

}

function setValuesWhileLoadingQuestions(question_json, selected_question) {
    selected_question.querySelector('select#types-questions').value = question_json.type.replace(/[_]+/g, " ");
    let question_type = selected_question.querySelector('select#types-questions');
    let question_content = selected_question.querySelector('.questionnaire_builder_forms_question_contenu_question');
    question_content.parentNode.replaceChild(question_editor_type_update(question_content, question_type), question_content)
    let type_question_lignes = ['Choix Multiples', 'Cases à cocher', 'Liste déroulante', 'Texte Court', 'Texte Long', 'Nombre', 'Date', 'Echelle linéaire'];
    let type_question_grille = ['Grille à choix multiples', 'Grille case à cocher'];
    if (type_question_lignes.includes(question_type)) {

    } else if (type_question_grille.includes(question_type)) {

    }
    return selected_question;
}

function setOptionsWhileLoadingQuestions(question_json, selected_question) {
    // selected_question.querySelector('select#types-questions').value = question_json.type.replace(/[_]+/g, " ");
    // return selected_question;
}

// NEW MODULES ================================================================================================================
function new_question_html() {
    let id = id_generator(10);
    if(document.getElementsByClassName('questionnaire_builder_forms_question ').length > 1) { id = "123ABC123A"}
    let html_question = `<div class="questionnaire_builder_forms_question selected_question" id="${id}">
                            <div class="questionnaire_builder_forms_question_head">
                                <div class="questionnaire_builder_forms_question_titre">
                                    <label for="question-titre">Question :</label>
                                    <input type="text" placeholder="Question" id="question-titre" required minlength="1">
                                </div>
                                <div class="questionnaire_builder_forms_question_type_question">
                                    <label for="types-questions">Type :</label>
                                    <select id="types-questions" class="type_question_selector">
                                        <option value="">Type de question</option>
                                        <option value="Texte Court">Texte Court</option>
                                        <option value="Texte Long">Texte Long</option>
                                        <option value="Choix Multiples">Choix Multiples</option>
                                        <option value="Cases à cocher">Cases à cocher</option>
                                        <option value="Liste déroulante">Liste déroulante</option>
                                        <option value="Echelle linéaire">Echelle linéaire</option>
                                        <option value="Grille à choix multiples">Grille à choix multiples</option>
                                        <option value="Grille case à cocher">Grille case à cocher</option>
                                        <option value="Date">Date</option>
                                        <option value="Nombre">Nombre</option>
                                    </select>
                                </div>
                            </div>
                            <div class="questionnaire_builder_forms_question_main">
                                <div class="questionnaire_builder_forms_question_contenu_question">
                                </div>
                            </div>
                            <div class="questionnaire_builder_forms_question_options">
                                <div class="questionnaire_builder_forms_question_options_show">
                                    <div class="separator"></div>
                                    <div class="commentaire">Configurez ici les conditions d'affichage de la question</div>
                                    <div class="options_critere">
                                        <select id="option-critere-condition" class="question_selector">
                                            <option value="and">SI</option>    
                                            <option value="and">ET</option>
                                            <option value="or">OU</option>
                                        </select>
                                        <select id="option-critere-question" class="question_selector" style="width: 25%">
                                            <option value="">Question</option>
                                            <option value="NAME">X</option>
                                        </select>
                                        <select id="option-critere-mode" class="question_selector">
                                            <option value="contain">est parmi</option>
                                            <option value="exclude">n'est pas parmi</option>
                                            <option value="equal">est égal</option>
                                            <option value="above">est supérieur</option>
                                            <option value="below">est inférieur</option>
                                        </select>
                                        <select id="option_critere_show_value_0" style="width: 50%" class="js_multiple_options_show_${id} question_selector option_critere_show_value" name="show_critere_${id}[]" multiple="multiple">
                                            <option value="x">X</option>
                                            <option value="y">Y</option>
                                        </select>
                                    </div>
                                    <div class="addRmvOptionBtn">
                                        <a class="addCritere"><i class="fas fa-plus-circle"></i></a>
                                        <a class="removeCritere"><i class="fas fa-minus-circle"></i></a>
                                    </div>
                                </div>
                                <div class="questionnaire_builder_forms_question_options_redirect">
                                    <div class="separator"></div>
                                    <div class="commentaire">Configurez ici le renvoie de la question en fonction de la réponse à celle-ci. <i>Ceci concerne la dernière question de la section.</i></div>
                                    <div class="options_critere">
                                        <div class="texte"> Si réponse </div>
                                        <select id="option-critere-mode" class="question_selector">
                                            <option value="contain">est parmi</option>
                                            <option value="exclude">n'est pas parmi</option>
                                            <option value="equal">est égal</option>
                                            <option value="above">est supérieur</option>
                                            <option value="below">est inférieur</option>
                                        </select>
                                        <select id="option_critere_redirect_value_0" style="width: 30%" class="js_multiple_options_redirect_${id} question_selector option_critere_redirect_value" name="redirect_critere_${id}[]" multiple="multiple">
                                        </select>
                                        <div class="texte"> rediriger vers </div>
                                        <select id="option-critere-redirection" class="question_selector" style="width: 25%">
                                            <option value="next_section">Prochaine section</option>
                                            <option value="end_forms">Terminer questionnaire</option>
                                        </select>
                                    </div>
                                    <div class="addRmvOptionBtn">
                                        <a class="addCritere"><i class="fas fa-plus-circle"></i></a>
                                        <a class="removeCritere"><i class="fas fa-minus-circle"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="questionnaire_builder_forms_question_footer">
                                <a class="removeQuestion"><i class="fas fa-trash"></i></a>
                                <div class="verticalLine"></div>
                                <label class="switch" style="--switch-size: 35px;">
                                    <input type="checkbox" class="question_obligatoire">
                                    <span class="slider round"></span>
                                </label>
                                <div class="text">Obligatoire</div>
                                <div class="verticalLine"></div>
                                <a class="showConditions"><i class="far fa-eye"></i></a>
                                <a class="redirectToSection"><i class="fas fa-level-down-alt"></i></a>
                            </div>
                        </div>`
                        
    return ConvertStringToHTML(html_question).children[0]
}

function load_question_html(question) {
    let id = id_generator(10);
    if(document.getElementsByClassName('questionnaire_builder_forms_question ').length > 1) { id = "123ABC123A"}
    let html_question = `<div class="questionnaire_builder_forms_question selected_question" id="${id}">
                            <div class="questionnaire_builder_forms_question_head">
                                <div class="questionnaire_builder_forms_question_titre">
                                    <label for="question-titre">Question :</label>
                                    <input type="text" placeholder="Question" id="question-titre" required minlength="1" value="${question.titre.replace(/[_]+/g, " ")}">
                                </div>
                                <div class="questionnaire_builder_forms_question_type_question">
                                    <label for="types-questions">Type :</label>
                                    <select id="types-questions" class="type_question_selector">
                                        <option value="">Type de question</option>
                                        <option value="Texte Court">Texte Court</option>
                                        <option value="Texte Long">Texte Long</option>
                                        <option value="Choix Multiples">Choix Multiples</option>
                                        <option value="Cases à cocher">Cases à cocher</option>
                                        <option value="Liste déroulante">Liste déroulante</option>
                                        <option value="Echelle linéaire">Echelle linéaire</option>
                                        <option value="Grille à choix multiples">Grille à choix multiples</option>
                                        <option value="Grille case à cocher">Grille case à cocher</option>
                                        <option value="Date">Date</option>
                                        <option value="Nombre">Nombre</option>
                                    </select>
                                </div>
                            </div>
                            <div class="questionnaire_builder_forms_question_main">
                                <div class="questionnaire_builder_forms_question_contenu_question">
                                </div>
                            </div>
                            <div class="questionnaire_builder_forms_question_options">
                                <div class="questionnaire_builder_forms_question_options_show">
                                    <div class="separator"></div>
                                    <div class="commentaire">Configurez ici les conditions d'affichage de la question</div>
                                    <div class="options_critere">
                                        <select id="option-critere-condition" class="question_selector">
                                            <option value="and">SI</option>    
                                            <option value="and">ET</option>
                                            <option value="or">OU</option>
                                        </select>
                                        <select id="option-critere-question" class="question_selector" style="width: 25%">
                                            <option value="">Question</option>
                                            <option value="NAME">X</option>
                                        </select>
                                        <select id="option-critere-mode" class="question_selector">
                                            <option value="contain">est parmi</option>
                                            <option value="exclude">n'est pas parmi</option>
                                            <option value="equal">est égal</option>
                                            <option value="above">est supérieur</option>
                                            <option value="below">est inférieur</option>
                                        </select>
                                        <select id="option_critere_show_value_0" style="width: 50%" class="js_multiple_options_show_${id} question_selector option_critere_show_value" name="show_critere_${id}[]" multiple="multiple">
                                            <option value="x">X</option>
                                            <option value="y">Y</option>
                                        </select>
                                    </div>
                                    <div class="addRmvOptionBtn">
                                        <a class="addCritere"><i class="fas fa-plus-circle"></i></a>
                                        <a class="removeCritere"><i class="fas fa-minus-circle"></i></a>
                                    </div>
                                </div>
                                <div class="questionnaire_builder_forms_question_options_redirect">
                                    <div class="separator"></div>
                                    <div class="commentaire">Configurez ici le renvoie de la question en fonction de la réponse à celle-ci. <i>Ceci concerne la dernière question de la section.</i></div>
                                    <div class="options_critere">
                                        <div class="texte"> Si réponse </div>
                                        <select id="option-critere-mode" class="question_selector">
                                            <option value="contain">est parmi</option>
                                            <option value="exclude">n'est pas parmi</option>
                                            <option value="equal">est égal</option>
                                            <option value="above">est supérieur</option>
                                            <option value="below">est inférieur</option>
                                        </select>
                                        <select id="option_critere_redirect_value_0" style="width: 30%" class="js_multiple_options_redirect_${id} question_selector option_critere_redirect_value" name="redirect_critere_${id}[]" multiple="multiple">
                                        </select>
                                        <div class="texte"> rediriger vers </div>
                                        <select id="option-critere-redirection" class="question_selector" style="width: 25%">
                                            <option value="next_section">Prochaine section</option>
                                            <option value="end_forms">Terminer questionnaire</option>
                                        </select>
                                    </div>
                                    <div class="addRmvOptionBtn">
                                        <a class="addCritere"><i class="fas fa-plus-circle"></i></a>
                                        <a class="removeCritere"><i class="fas fa-minus-circle"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="questionnaire_builder_forms_question_footer">
                                <a class="removeQuestion"><i class="fas fa-trash"></i></a>
                                <div class="verticalLine"></div>
                                <label class="switch" style="--switch-size: 35px;">
                                    <input type="checkbox" class="question_obligatoire">
                                    <span class="slider round"></span>
                                </label>
                                <div class="text">Obligatoire</div>
                                <div class="verticalLine"></div>
                                <a class="showConditions"><i class="far fa-eye"></i></a>
                                <a class="redirectToSection"><i class="fas fa-level-down-alt"></i></a>
                            </div>
                        </div>`
    return ConvertStringToHTML(html_question).children[0]
}

function new_title_html() {
    let html_question = `<div class="questionnaire_builder_forms_question selected_question" id="${id_generator(10)}">
                            <div class="questionnaire_builder_forms_question_head">
                                <div class="questionnaire_builder_forms_question_titre">
                                    <label for="question-titre">Titre :</label>
                                    <input type="text" placeholder="Titre" id="question-titre" required minlength="1">
                                </div>
                                <div class="questionnaire_builder_forms_question_type_question" style="display: none;">
                                    <label for="types-questions">Type :</label>
                                    <select id="types-questions" class="type_question_selector" value="titre">
                                        <option value="titre">titre</option>
                                    </select>
                                </div>
                            </div>
                            <div class="questionnaire_builder_forms_question_main">
                                <div class="questionnaire_builder_forms_question_contenu_question">
                                </div>
                            </div>
                            <div class="questionnaire_builder_forms_question_footer">
                                <a class="removeQuestion"><i class="fas fa-trash"></i></a>
                            </div>
                        </div>`
    return ConvertStringToHTML(html_question).children[0]
}

function new_section_html() {
    let html_question = `<div class="questionnaire_builder_forms_question selected_question" id="${id_generator(10)}">
                            <div class="questionnaire_builder_forms_question_head">
                                <div class="questionnaire_builder_forms_question_titre">
                                    <label for="question-titre">Titre de section :</label>
                                    <input type="text" placeholder="Titre de section" id="question-titre" required minlength="1">
                                </div>
                                <div class="questionnaire_builder_forms_question_type_question" style="display: none;">
                                    <label for="types-questions">Type :</label>
                                    <select id="types-questions" class="type_question_selector" value="titre">
                                        <option value="section">section</option>
                                    </select>
                                </div>
                            </div>
                            <div class="questionnaire_builder_forms_question_main">
                                <div class="questionnaire_builder_forms_question_contenu_question">
                                </div>
                            </div>
                            <div class="questionnaire_builder_forms_question_footer">
                                <a class="removeQuestion"><i class="fas fa-trash"></i></a>
                            </div>
                        </div>`
    return ConvertStringToHTML(html_question).children[0]
}

// OPTIONS ====================================================================================================================
function active_question_trash_eventlistener(active_question, trash_btn) {
    trash_btn.addEventListener('click', (event) => {
        removeQuestion(active_question);
    });
}

function active_question_show_options_eventlistener(active_question, show_conditions_btn) {
    show_conditions_btn.addEventListener('click', (event) => {
        let show_conditions_element = active_question.getElementsByClassName('questionnaire_builder_forms_question_options_show')[0];
        if(show_conditions_element.style.display === 'none' || show_conditions_element.style.display === '') {
            show_conditions_element.style.display = 'flex';
            $(function() {
                initShowOptionsDropdown(active_question);
                $(`.js_multiple_options_show_${active_question.id}`).select2();
                active_options_add_remove_btn(active_question, "show");
            })
        } else {
            show_conditions_element.style.display = 'none';
            $(function() {
                $(`.js_multiple_options_show_${active_question.id}`).select2("destroy");
            })
        }
    });
}

function active_question_redirect_options_eventlistener(active_question, redirect_to_section_btn) {
    redirect_to_section_btn.addEventListener('click', (event) => {
        let show_redirect_element = active_question.getElementsByClassName('questionnaire_builder_forms_question_options_redirect')[0];
        if(show_redirect_element.style.display === 'none' || show_redirect_element.style.display === '') {
            show_redirect_element.style.display = 'flex';
            $(function() {
                initRedirectOptionsDropdown(active_question);
                $(`.js_multiple_options_redirect_${active_question.id}`).select2();
                active_options_add_remove_btn(active_question, "redirect");
            })
        } else {
            show_redirect_element.style.display = 'none';
            $(function() {
                $(`.js_multiple_options_redirect_${active_question.id}`).select2("destroy");
            })
        }
    });
}

function initShowOptionsDropdown(active_question) {
    let questions_dropdown_values = [];
    let awnser_dropdown_values = [];
    let question_type = active_question.querySelectorAll('select.type_question_selector')[0].value;
    let questions = document.querySelectorAll('.questionnaire_builder_forms_question');
    let not_question_types = ['titre', 'section'];
    for(let question of questions) {
        if(not_question_types.includes(question.querySelectorAll('select.type_question_selector')[0].value) === false) {
            let obj = {};
            obj.key = question.id;
            obj.value = question.querySelector("input#question-titre").value;
            questions_dropdown_values.push(obj);
        }
    }
    replaceAwnserDropdownValues(active_question, questions_dropdown_values, '#option-critere-question', "show");
    let options_criteres = active_question.querySelectorAll('div.questionnaire_builder_forms_question_options_show > div.options_critere');
    for(let option_critere of options_criteres) {
        let critere_question = option_critere.querySelector('select#option-critere-question');
        $(option_critere.querySelector('.option_critere_show_value')).select2();
        change_show_options_value(critere_question, option_critere);
        critere_question.addEventListener('change', (event) => {
            change_show_options_value(critere_question, option_critere);
        })
    }
}

function initRedirectOptionsDropdown(active_question) {
    let awnser_dropdown_values = [];
    let sections_dropdown_values = [];
    let question_type = active_question.querySelectorAll('select.type_question_selector')[0].value;
    let questions = document.querySelectorAll('.questionnaire_builder_forms_question');
    let mcq = ['Choix Multiples', 'Cases à cocher', 'Liste déroulante'];
    let grille = ['Grille à choix multiples', 'Grille case à cocher'];
    let input = ['Texte Court', 'Texte Long'];
    let intDate = ['Nombre', 'Date', 'Echelle linéaire'];
    if(mcq.includes(question_type)) {
        let reponses = active_question.getElementsByClassName('question_lignes')[0].querySelectorAll("input");
        for(let reponse of reponses) {
            let obj = {};
            obj.key = reponse.value;
            obj.value = reponse.value;
            awnser_dropdown_values.push(obj);
        }
    } else if(grille.includes(question_type)) {
        let reponses = active_question.getElementsByClassName('question_grille_lignes')[0].querySelectorAll("input");
        let colonnes = active_question.getElementsByClassName('question_grille_colonnes')[0].querySelectorAll("input");
        for(let colonne of colonnes) {
            for(let reponse of reponses) {
                let obj = {};
                obj.key = `${colonne.value}: ${reponse.value}`;
                obj.value = `${colonne.value}: ${reponse.value}`;
                awnser_dropdown_values.push(obj);
            }
        }
    }
    for(let question of questions) {
        if(question.querySelectorAll('select#types-questions')[0].value === "section") {
            let obj = {};
            obj.key = question.id;
            obj.value = question.querySelector('.questionnaire_builder_forms_question_titre').querySelector('input#question-titre').value;
            sections_dropdown_values.push(obj);
        }
    }
    replaceAwnserDropdownValues(active_question, awnser_dropdown_values, '.option_critere_redirect_value', "redirect");
    pushAwnserDropdownValues(active_question, sections_dropdown_values, '#option-critere-redirection', "redirect");
}

function pushAwnserDropdownValues(active_question, values, querySelectorName, option_type) {
    let options_criteres = [];
    if(option_type === "redirect") {
        options_criteres = active_question.querySelectorAll('.questionnaire_builder_forms_question_options_redirect > div.options_critere')
    } else if(option_type === "show") {
        options_criteres = active_question.querySelectorAll('.questionnaire_builder_forms_question_options_show > div.options_critere')
    }
    let html = "";
    for(let value of values) {
        html += `<option value="${value.key.replace(/ /g, "_")}">${value.value}</option>\n`;
    }
    for(let options_critere of options_criteres) {
        let old_val = $(options_critere.querySelectorAll(querySelectorName)[0]).val();
        options_critere.querySelectorAll(querySelectorName)[0].innerHTML += html;
        $(options_critere.querySelectorAll(querySelectorName)[0]).val(old_val);
    }
}

function replaceAwnserDropdownValues(active_question, values, querySelectorName, option_type) {
    let options_criteres = [];
    if(option_type === "redirect") {
        options_criteres = active_question.querySelectorAll('.questionnaire_builder_forms_question_options_redirect > div.options_critere')
    } else if(option_type === "show") {
        options_criteres = active_question.querySelectorAll('.questionnaire_builder_forms_question_options_show > div.options_critere')
    }
    let html = "";
    for(let value of values) {
        html += `<option value="${value.key.replace(/[_]+/g, " ")}">${value.value}</option>\n`;
    }
    for(let options_critere of options_criteres) {
        let old_val = $(options_critere.querySelectorAll(querySelectorName)[0]).val();
        options_critere.querySelectorAll(querySelectorName)[0].innerHTML = html;
        $(options_critere.querySelectorAll(querySelectorName)[0]).val(old_val);
    }
}

function active_options_add_remove_btn(active_question, type_question) {
    if(type_question === "show") {
        let show_options_btns = active_question.querySelector('div.questionnaire_builder_forms_question_options_show > div.addRmvOptionBtn');
        let addBtn = show_options_btns.querySelector('a.addCritere');
        let rmvBtn = show_options_btns.querySelector('a.removeCritere');
        addBtn.addEventListener('click', (event) => {
            var previous_element = show_options_btns.previousElementSibling;
            $(previous_element.querySelectorAll('.option_critere_show_value')).select2("destroy");
            var new_element = previous_element.cloneNode(true);
            new_element.querySelector('.option_critere_show_value').id = `option_critere_show_value_${active_question.querySelectorAll('div.questionnaire_builder_forms_question_options_show > .options_critere').length}`
            show_options_btns.before(new_element);
            $(previous_element.querySelectorAll('.option_critere_show_value')).select2();
            $(new_element.querySelectorAll('.option_critere_show_value')).select2();
        })
        rmvBtn.addEventListener('click', (event) => {
            var previous_element = show_options_btns.previousElementSibling;
            if(show_options_btns.parentNode.querySelectorAll('.options_critere').length > 1) {
                show_options_btns.parentNode.removeChild(previous_element)
            }
        })
    } else if(type_question === "redirect") {
        let show_options_btns = active_question.querySelector('div.questionnaire_builder_forms_question_options_redirect > div.addRmvOptionBtn');
        let addBtn = show_options_btns.querySelector('a.addCritere');
        let rmvBtn = show_options_btns.querySelector('a.removeCritere');
        addBtn.addEventListener('click', (event) => {
            var previous_element = show_options_btns.previousElementSibling;
            $(previous_element.querySelectorAll('.option_critere_redirect_value')).select2("destroy");
            var new_element = previous_element.cloneNode(true);
            new_element.querySelector('.option_critere_redirect_value').id = `option_critere_redirect_value_${active_question.querySelectorAll('div.questionnaire_builder_forms_question_options_redirect > .options_critere').length}`
            show_options_btns.before(new_element);
            $(previous_element.querySelectorAll('.option_critere_redirect_value')).select2();
            $(new_element.querySelectorAll('.option_critere_redirect_value')).select2();
        })
        rmvBtn.addEventListener('click', (event) => {
            var previous_element = show_options_btns.previousElementSibling;
            if(show_options_btns.parentNode.querySelectorAll('.options_critere').length > 1) {
                show_options_btns.parentNode.removeChild(previous_element)
            }
        })
    }
}

function change_show_options_value(critere_question, option_critere) {
    let mcq = ['Choix Multiples', 'Cases à cocher', 'Liste déroulante'];
    let grille = ['Grille à choix multiples', 'Grille case à cocher'];
    let input = ['Texte Court', 'Texte Long'];
    let intDate = ['Nombre', 'Date', 'Echelle linéaire'];
    let awnser_dropdown_values = [];
    if(critere_question.value === "") {
        critere_question.value = document.querySelector('.selected_question').id
    }
    let question_select = document.getElementById(critere_question.value);
    let question_select_type = question_select.querySelectorAll('select.type_question_selector')[0].value;
    if(mcq.includes(question_select_type)) {
        let reponses = question_select.getElementsByClassName('question_lignes')[0].querySelectorAll("input");
        for(let reponse of reponses) {
            let obj = {};
            obj.key = reponse.value;
            obj.value = reponse.value;
            awnser_dropdown_values.push(obj);
        }
    } else if(grille.includes(question_select_type)) {
        let reponses = question_select.getElementsByClassName('question_grille_lignes')[0].querySelectorAll("input");
        let colonnes = question_select.getElementsByClassName('question_grille_colonnes')[0].querySelectorAll("input");
        for(let colonne of colonnes) {
            for(let reponse of reponses) {
                let obj = {};
                obj.key = `${colonne.value}: ${reponse.value}`;
                obj.value = `${colonne.value}: ${reponse.value}`;
                awnser_dropdown_values.push(obj);
            }
        }
    }
    $(option_critere.querySelector('.option_critere_show_value')).select2("destroy");
    replaceAwnserDropdownValues(document.querySelector('.selected_question'), awnser_dropdown_values, '.option_critere_show_value', "show");
    $(option_critere.querySelector('.option_critere_show_value')).select2();
}
// SHARED FUNCTIONS ===========================================================================================================
function id_generator(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function ConvertStringToHTML(str) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, 'text/html');
    return doc.body;
};

function eventListenerRemoverAll(elements) {
    for(var element of elements){
        let old_id = element.id;
        let show_options_select2 = element.getElementsByClassName(`js_multiple_options_show_${old_id}`)[0];
        let redirect_options_select2 = element.getElementsByClassName(`js_multiple_options_redirect_${old_id}`)[0];
        if(show_options_select2 !== undefined) {
            if(show_options_select2.classList.contains('select2-hidden-accessible')) {
                $(`.js_multiple_options_show_${old_id}`).select2("destroy");
                element.getElementsByClassName('questionnaire_builder_forms_question_options_show')[0].style.display = 'none';
            }
            if(redirect_options_select2.classList.contains('select2-hidden-accessible')) {
                $(`.js_multiple_options_redirect_${old_id}`).select2("destroy");
                element.getElementsByClassName('questionnaire_builder_forms_question_options_redirect')[0].style.display = 'none';
            }
        }
        let ClonedElement = element.cloneNode(true);
        setOldValues(element, ClonedElement);
        element.parentNode.replaceChild(ClonedElement, element);
    }
}

function setOldValues(oldElement, clonedElement) {
    // Question ID
    clonedElement.id = oldElement.id;
    //  Type values
    let old_type = oldElement.getElementsByClassName('type_question_selector')[0].value;
    clonedElement.getElementsByClassName('type_question_selector')[0].value = old_type;
    // IF NOT A QUESTION
    let not_question_types = ['titre', 'section'];
    if(not_question_types.includes(old_type) === false) {
        // Options Critères Show Select Values
        let old_options_show_criteres = oldElement.getElementsByClassName('questionnaire_builder_forms_question_options_show')[0].getElementsByClassName('options_critere')
        let cloned_options_show_criteres = clonedElement.getElementsByClassName('questionnaire_builder_forms_question_options_show')[0].getElementsByClassName('options_critere')
        for(var i = 0; i < cloned_options_show_criteres.length; i++) {
            var old_condition = old_options_show_criteres[i].children[0].value;
            var old_question = old_options_show_criteres[i].children[1].value;
            var old_mode = old_options_show_criteres[i].children[2].value;
            var old_selected_values = $(old_options_show_criteres[i].children[3]).val();
            cloned_options_show_criteres[i].children[0].value = old_condition;
            cloned_options_show_criteres[i].children[1].value = old_question;
            cloned_options_show_criteres[i].children[2].value = old_mode;
            $(cloned_options_show_criteres[i].children[3]).val(old_selected_values).trigger("change");;
        }
        // Options Critères Redirect Select Values
        let old_options_redirect_criteres = oldElement.getElementsByClassName('questionnaire_builder_forms_question_options_redirect')[0].getElementsByClassName('options_critere')
        let cloned_options_redirect_criteres = clonedElement.getElementsByClassName('questionnaire_builder_forms_question_options_redirect')[0].getElementsByClassName('options_critere')
        for(var i = 0; i < cloned_options_redirect_criteres.length; i++) {
            var old_mode = old_options_redirect_criteres[i].children[1].value;
            var old_selected_values = $(old_options_redirect_criteres[i].children[2]).val();
            var old_redirection = old_options_redirect_criteres[i].children[4].value;
            cloned_options_redirect_criteres[i].children[1].value = old_mode;
            $(cloned_options_redirect_criteres[i].children[2]).val(old_selected_values).trigger("change");
            cloned_options_redirect_criteres[i].children[4].value = old_redirection;
        }
    }
}


export { initQuestionnaireBuilder };

// Load question
// Save Auto -> Non urgent
// .replace(/[_]+/g, " ")