import React, { useState, useEffect } from 'react';

const ChapterNavigation = ({ chapters, currentLesson, onLessonClick, lessonProgresses }) => {
    const [currentChapterIndex, setCurrentChapterIndex] = useState(() => {
        return chapters.findIndex(chapter =>
            chapter.lessons.some(lesson => lesson.id === currentLesson.id)
        );
    });

    useEffect(() => {
        const newChapterIndex = chapters.findIndex(chapter =>
            chapter.lessons.some(lesson => lesson.id === currentLesson.id)
        );
        setCurrentChapterIndex(newChapterIndex);
    }, [currentLesson, chapters]);

    const handlePreviousChapter = () => {
        if (currentChapterIndex > 0) {
            setCurrentChapterIndex(currentChapterIndex - 1);
        }
    };

    const handleNextChapter = () => {
        if (currentChapterIndex < chapters.length - 1) {
            setCurrentChapterIndex(currentChapterIndex + 1);
        }
    };

    return (
        <div className="chapter-navigation">
            <div className="chapter-header">
                <div className="chapter-controls">
                    <button onClick={handlePreviousChapter}>&lt;</button>
                    <div className="chapter-title">{chapters[currentChapterIndex].title}</div>
                    <button onClick={handleNextChapter}>&gt;</button>
                </div>
            </div>
            <div className="lesson-list">
                <ul>
                    {chapters[currentChapterIndex].lessons.map((lesson) => (
                        <li
                            key={lesson.id}
                            className={`${lesson.id === currentLesson.id ? 'current' : ''} ${lessonProgresses[lesson.id] === 1 ? 'completed' : ''}`}
                            onClick={() => onLessonClick(lesson)}
                        >
                            {lesson.title}
                            {lesson.id === currentLesson.id }
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ChapterNavigation;
