import React, { useState, useEffect } from 'react';
import { Oval } from 'react-loader-spinner';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const ImportStatusModal = ({ jobId, onClose, juniorId }) => {
    const [status, setStatus] = useState('in_progress');
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetch(`import_status?job_id=${jobId}`)
                .then(response => response.json())
                .then(data => {
                    if (data.status === 'in_progress') {
                        setStatus('in_progress');
                    } else if (data.status === 'success') {
                        setStatus('success');
                        clearInterval(intervalId);
                    } else if (data.status === 'error') {
                        setStatus('error');
                        setErrors(data.errors);
                        clearInterval(intervalId);
                    }
                })
                .catch(error => {
                    setStatus('error');
                    setErrors([error.message]);
                    clearInterval(intervalId);
                });
        }, 2000);

        return () => clearInterval(intervalId);
    }, [jobId]);
    const handleClose = () => {
        window.location.href = `/juniors/${juniorId}`;
    };
    const renderContent = () => {
        switch (status) {
            case 'in_progress':
                return <Oval color="#00BFFF" height={80} width={80} />;
            case 'success':
                return <FaCheckCircle size={80} color="green" />;
            case 'error':
                return <FaTimesCircle size={80} color="red" />;
            default:
                return null;
        }
    };

    return (
        <div className="import-status-modal">
            <div className="impoort-modal-content">
                {renderContent()}
                <div className="status-message">
                    {status === 'in_progress' && 'Importation en cours...'}
                    {status === 'success' && 'Importation réussie !'}
                    {status === 'error' && (
                        <>
                            <p>Importation échouée :</p>
                            <ul>
                                {errors.map((error, index) => (
                                    <li key={index}>{error}</li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
                {status !== 'in_progress' && (
                    <button onClick={handleClose} className="import-close-button">Fermer</button>
                )}
            </div>
        </div>
    );
};

export default ImportStatusModal;
