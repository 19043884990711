import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';


const initGanttV1Chart = () => {
    if (document.getElementById('ganttv1chart') != null) {
        var ctx = document.getElementById("ganttv1chart").getContext('2d');
        var today = new Date();
        var datasets = JSON.parse(ctx.canvas.dataset.data);

        // Parse DataSets
        var parsedDataSet = []
        for (let i = 0; i < datasets.length; i++) {
            const dataset = datasets[i];
            var result = dataset.reduce((map, obj) => {
                map[obj[0]] = obj[1];
                return map;
            }, {});
            parsedDataSet.push(result)
        };

        
        // Chart
        var myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: JSON.parse(ctx.canvas.dataset.labels),
                datasets: parsedDataSet
                //JSON.parse(ctx.canvas.dataset.data),
            },
            options: {
                indexAxis: 'y',
                scales: {
                    y: {
                    display: true,
                    ticks: {
                        color: "#000000",
                        font: {
                            family: "'Poppins', sans-serif",
                            size: 14,
                            weight: 800,
                        },
                    }
                },
                x: {
                    display: true,
                    type: 'time',
                    min: today,
                        time: {
                            unit: 'day',
                        },
                        ticks: {
                            color: "#000000",
                            font: {
                                family: "'Poppins', sans-serif",
                                size: 14,
                                weight: 800,
                            },
                        }
                    },
                },
                responsive: true,
                animations: {
                    radius: {
                    duration: 1500,
                    easing: 'linear',
                    loop: (context) => context.active
                    }
                },
                plugins: {
                    legend: {
                        display: false,
                        position: 'bottom',
                        labels: {
                            fontColor: "black",
                            fontSize: 30,
                        }
                    },
                    title: {
                        display: false,
                        text: "Chiffre d'Affaire de la structure"
                    }
                },
            },
        });
    }
}

export { initGanttV1Chart };