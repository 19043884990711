const initAlert = () => {
    const closeButtons = document.querySelectorAll('.alert .close');

    closeButtons.forEach(button => {
        button.addEventListener('click', () => {
            const alert = button.closest('.alert');
            if (alert) {
                alert.style.display = 'none';
            }
        });
    });
}

export { initAlert };
