const initFilter = () => {
    const setupFilters = () => {
        const filterPanels = document.querySelectorAll('.filter-panel');
        const openFiltersBtns = document.querySelectorAll('.open-filters-btn');
        const overlay = document.querySelector('.overlay');
        const toggleOverlay = (isVisible) => {
            overlay.style.display = isVisible ? 'block' : 'none';
        };

        filterPanels.forEach((filterPanel, index) => {
            const openFiltersBtn = openFiltersBtns[index];
            const navbar = document.querySelector('.navbar');

            if (!navbar || !filterPanel || !openFiltersBtn) {
                return;
            }


            const adjustFilterPanelPosition = () => {
                if (navbar && filterPanel) {
                    const navbarHeight = navbar.offsetHeight;
                    filterPanel.style.top = navbarHeight + 'px';
                    filterPanel.style.height = `calc(100% - ${navbarHeight}px)`;
                }
            };

            const handleClickOutside = (event) => {
                if (filterPanel.classList.contains('active') && !filterPanel.contains(event.target) && !openFiltersBtn.contains(event.target)) {
                    filterPanel.classList.remove('active');
                    toggleOverlay(false);

                }
            };

            if (openFiltersBtn) {
                openFiltersBtn.addEventListener('click', function() {
                    const isActive = filterPanel.classList.toggle('active');
                    toggleOverlay(isActive);
                    if (isActive) {
                        adjustFilterPanelPosition();
                    }
                });
            }

            document.addEventListener('click', handleClickOutside);

            adjustFilterPanelPosition();
        });
    };

    if (document.readyState === "loading") {
        document.addEventListener("DOMContentLoaded", setupFilters);
    } else {
        setupFilters();
    }
};

export { initFilter };
