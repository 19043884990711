const initItemToChoose = () => {
    if (document.getElementsByClassName("item_to_choose_selector").length > 0) {
        let selector = document.getElementsByClassName("item_to_choose_selector")[0].children[0].children[1];
        let containers = document.getElementsByClassName("item_to_choose")

        let selector_value = selector.value;
        for(let container of containers) {
            let names = container.getAttribute('name').split(' ');
            let class_list = container.classList;
            for(let name of names) {
                if(name == selector_value) {
                    if(class_list.contains('hidden')) {
                        container.classList.remove('hidden')
                    }
                    break
                }
                if(name != selector_value && class_list.contains('hidden') == false) {
                    container.classList.add('hidden')
                }
            }
        };

        selector.addEventListener('change', (event) => {
            let selector_value = selector.value;

            for(let container of containers) {
                let names = container.getAttribute('name').split(' ');
                let class_list = container.classList;
                for(let name of names) {
                    if(name == selector_value) {
                        if(class_list.contains('hidden')) {
                            container.classList.remove('hidden')
                        }
                        break
                    }
                    if(name != selector_value && class_list.contains('hidden') == false) {
                        container.classList.add('hidden')
                    }
                }
            };
        })
    }
}

export { initItemToChoose };