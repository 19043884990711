import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Timer = ({ currentLessonId, junior, current_user, onUpdateProgress }) => {
    const timerRef = useRef(null);
    const startTimeRef = useRef(null);
    const [lastProgress, setLastProgress] = useState(null);

    const fetchLessonProgress = async (lessonId) => {
        const response = await fetch(`/juniors/${junior.current_junior}/lesson_progresses/find_or_create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({ user_id: current_user, lesson_id: lessonId })
        });
        const data = await response.json();
        return data;
    };

    const updateLessonTime = async (progressId, startTime) => {
        if (!startTime) return;
        const endTime = new Date().getTime();
        const timeSpent = Math.floor((endTime - startTime) / 1000);

        const response = await fetch(`/juniors/${junior.current_junior}/lesson_progresses/${progressId}/update_time`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({ time: timeSpent })
        });

        if (response.ok) {
            const updatedProgress = await response.json();
            onUpdateProgress(updatedProgress.lesson_id, updatedProgress.progress);
        }
    };

    const startTimer = async (lessonId) => {
        const currentProgress = await fetchLessonProgress(lessonId);
        if (currentProgress.progress < 1) {
            setLastProgress(currentProgress);
            startTimeRef.current = new Date().getTime();
            timerRef.current = setInterval(() => {}, 1000);
        } else {
            setLastProgress(null);
        }
    };

    const stopTimer = async () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
            timerRef.current = null;
            if (lastProgress) {
                await updateLessonTime(lastProgress.id, startTimeRef.current);
                setLastProgress(null);
            }
        }
    };

    useEffect(() => {
        const handleUnload = async () => {
            await stopTimer();
        };

        window.addEventListener('beforeunload', handleUnload);
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, []);

    useEffect(() => {
        const handleLessonChange = async () => {
            await stopTimer();
            await startTimer(currentLessonId);
        };

        handleLessonChange();
    }, [currentLessonId]);

    return null;
};

Timer.propTypes = {
    currentLessonId: PropTypes.number.isRequired,
    junior: PropTypes.shape({
        current_junior: PropTypes.number.isRequired,
    }).isRequired,
    current_user: PropTypes.number.isRequired,
    onUpdateProgress: PropTypes.func.isRequired,
};

export default Timer;
