import React from 'react';
import PropTypes from 'prop-types';

const LessonBlock = ({ lesson, isCurrent, progress, onClick }) => {
    return (
        <div
            className={`lesson-block ${isCurrent ? 'current' : ''} ${progress === 1 ? 'completed' : ''}`}
            onClick={onClick}
        >
        </div>
    );
};

LessonBlock.propTypes = {
    lesson: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    isCurrent: PropTypes.bool.isRequired,
    progress: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default LessonBlock;
