const initLessonElements = () => {
    const elementTypeSelect = document.querySelector('.element-type-select');
    const videoFields = document.querySelector('.video-fields');
    const textFields = document.querySelector('.text-fields');
    const qcmFields = document.querySelector('.qcm-fields');
    const fileUploadFields = document.querySelector('.file-upload-fields');

    if (elementTypeSelect) {
        elementTypeSelect.addEventListener('change', (e) => {
            const selectedType = e.target.value;
            videoFields.classList.add('hidden');
            textFields.classList.add('hidden');
            qcmFields.classList.add('hidden');
            fileUploadFields.classList.add('hidden');

            if (selectedType === 'Video') {
                videoFields.classList.remove('hidden');
            } else if (selectedType === 'Text') {
                textFields.classList.remove('hidden');
            } else if (selectedType === 'Dépot de fichier') {
                textFields.classList.remove('hidden');
            } else if (selectedType === 'QCM') {
                qcmFields.classList.remove('hidden');
            } else if (selectedType === 'FileUpload') {
                fileUploadFields.classList.remove('hidden');
            }
        });
    }
}

export { initLessonElements };
