import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ModelSelection = ({ onNext, models }) => {
    const [selectedModel, setSelectedModel] = useState('');

    const handleModelChange = (event) => {
        setSelectedModel(event.target.value);
    };

    const handleNext = () => {
        if (selectedModel) {
            onNext(selectedModel);
        } else {
            alert("Please select a model");
        }
    };

    return (
        <div className="model-selection">
            <select onChange={handleModelChange} value={selectedModel}>
                <option value="">Choisissez votre modèle</option>
                {models.map(model => (
                    <option key={model} value={model}>{model}</option>
                ))}
            </select>
            <button onClick={handleNext}>étape suivante</button>
        </div>
    );
};

ModelSelection.propTypes = {
    onNext: PropTypes.func.isRequired,
    models: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ModelSelection;
