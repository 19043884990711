import React, { useEffect, useRef, useState } from 'react';
import loadYouTubeAPI from './YouTubeAPI'; // Import the loader


const YouTubeVideo = ({ element, user, junior }) => {
    const [videoProgress, setLocalVideoProgress] = useState(null);
    const videoProgressRef = useRef(null);
    const [isPlayerReady, setIsPlayerReady] = useState(false);

    const playerRef = useRef(null);
    const lastTimeRef = useRef(0);
    const intervalRef = useRef(null);
    const timeThreshold = 2;
    const [isSeeking, setIsSeeking] = useState(false);
    const updateLastTime = (currentTime) => {
        console.log(currentTime)
        if (!isSeeking && currentTime > lastTimeRef.current) {
            lastTimeRef.current = currentTime;
        }
    };


    useEffect(() => {
        videoProgressRef.current = videoProgress;
    }, [videoProgress]);

    useEffect(() => {
        if (!element.youtube_url) {
            console.warn("Aucun ID de vidéo YouTube fourni pour l'élément :", element.id);
            return;
        }

        const initializePlayer = () => {
            if (!playerRef.current) {
                playerRef.current = new YT.Player(`youtube-player-${element.id}`, {
                    videoId: element.youtube_url,
                    events: {
                        'onReady': onPlayerReady,
                        'onStateChange': onPlayerStateChange,
                    },
                });
            }
        };

        loadYouTubeAPI().then(() => {
            initializePlayer();
        });

        fetchVideoProgress(element.id);

        return () => {
            clearInterval(intervalRef.current);
            if (playerRef.current) {
                playerRef.current.destroy();
                playerRef.current = null;
            }
        };
    }, []);

    const fetchVideoProgress = async (lessonElementId) => {
        try {
            const response = await fetch(`/juniors/${junior.current_junior}/video_progresses/find_or_create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({ user_id: user, lesson_element_id: lessonElementId })
            });
            const data = await response.json();
            setLocalVideoProgress(data);
        } catch (error) {
            console.error('Erreur lors de la récupération de la progression vidéo :', error);
        }
    };

    const onPlayerReady = (event) => {
        setIsPlayerReady(true);
    };

    useEffect(() => {
        if (isPlayerReady && videoProgress) {

            intervalRef.current = setInterval(() => {
                if (playerRef.current && playerRef.current.getCurrentTime) {
                    const currentTime = playerRef.current.getCurrentTime();

                    if (!isSeeking && currentTime > lastTimeRef.current + timeThreshold) {
                        setIsSeeking(true);
                        playerRef.current.seekTo(lastTimeRef.current);
                    } else if (isSeeking && currentTime <= lastTimeRef.current + timeThreshold) {
                        setIsSeeking(false);
                    } else if (!isSeeking) {
                        updateLastTime(currentTime);
                    }
                }
            }, 1000);
        }
    }, [isPlayerReady, videoProgress]);

    const onPlayerStateChange = (event) => {
        if (event.data === YT.PlayerState.ENDED) {
            clearInterval(intervalRef.current);
            handleVideoEnd();
        } else if (event.data === YT.PlayerState.PLAYING && isSeeking) {
            setIsSeeking(false);
        }
    };

    const handleVideoEnd = async () => {
        const currentVideoProgress = videoProgressRef.current;
        if (!currentVideoProgress) {
            return;
        }
        if (!currentVideoProgress.watched) {
            try {
                const response = await fetch(`/juniors/${junior.current_junior}/video_progresses/${currentVideoProgress.id}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                    },
                    body: JSON.stringify({ watched: true })
                });
                const data = await response.json();
                console.log('Progression vidéo mise à jour :', data);
                setLocalVideoProgress(data);
                setVideoProgress(data);
            } catch (error) {
                console.error('Erreur lors de la mise à jour de la progression vidéo :', error);
            }
        }
    };

    return (
        <div className="video-container">
            <div id={`youtube-player-${element.id}`} />
            {!videoProgress?.watched && (
                <p className="video-warning">
                    Vous devez regarder cette vidéo en entier afin de pouvoir la valider.
                </p>
            )}
        </div>
    );
};

export default YouTubeVideo;
