const initHEXTableauDynamique = () => {
    if(document.getElementsByClassName('hexTableauDynamique').length > 0) {
        let tableau_layouts = document.getElementsByClassName('hexTableauDynamique');
        for(let tableau_layout of tableau_layouts) {
            let tableau_init_data_json = tableau_layout.getAttribute('data-tableau-hex');
            let tableau_init_data = JSON.parse(tableau_init_data_json)[0];
            creerTableauStructure(tableau_layout);
            ajoutHeaderAuTableau(tableau_layout, tableau_init_data);
            ajoutContentAuTableau(tableau_layout, tableau_init_data);
            if(tableau_init_data["action_button"] !== "hidden") {
                initializeActionBtns(tableau_layout, tableau_init_data);
            }
            document.addEventListener('keyup', (event) => {
                sauvegarderLigneContenu(tableau_layout, tableau_init_data)
            });
        }
    }
}

function creerTableauStructure(tableau_layout) {
    var tableauHTML =   `
                            <table class="tableau" style="overflow: scroll; margin: 25px 0px;">
                                <thead class="tabl_header">
                                    <tr class="header_row">
                                    </tr>
                                </thead>
                                <tbody class="tabl_contenu">
                                </tbody>
                                <tfoot class="tableauActionBtn">
                                </tfoot>
                            </table>
                        `
    tableau_layout.innerHTML += tableauHTML
}

function generateActionTableLine(tableau_layout) {
    var nbre_colonnes = JSON.parse(tableau_layout.getAttribute('data-tableau-hex'))[0]["colonnes"].length
    if(nbre_colonnes > 1) {
        return `<td class="addRmvBtn">
                    <a class="addRow"><i class="fas fa-plus-circle"></i></a>
                    <a class="removeRow"><i class="fas fa-minus-circle"></i></a>
                </td>
                <td colspan="${nbre_colonnes - 1}" class="saveBtn">
                    <a class="saveTableBtn">Sauvegarder le tableau</a>
                </td>`
    } else {
        return `<td class="addRmvSaveBtn">
                    <a class="addRow"><i class="fas fa-plus-circle"></i></a>
                    <a class="removeRow"><i class="fas fa-minus-circle"></i></a>
                    <a class="saveTableBtn">Sauvegarder le tableau</a>
                </td>`
    }
}

function ajoutHeaderAuTableau(tableau_layout, tableau_init_data) {
    var headerRow = tableau_layout.getElementsByClassName('header_row')[0]
    var colonnes = tableau_init_data["colonnes"];
    for (var colonne of colonnes) {
        var colonneHTML = `<th class="colonne">${colonne["label"].replace(/[_]+/g, " ")}</th>`
        headerRow.innerHTML += colonneHTML
    }
}

function ajoutContentAuTableau(tableau_layout, tableau_init_data) {
    var contentRow = tableau_layout.getElementsByClassName('tabl_contenu')[0]
    var lignes = tableau_init_data["lignes"];
    var colonnes = tableau_init_data["colonnes"];
    for (var ligne of lignes) {
        var contentColonnesHTML = concatenerColonnesLigneContenu(colonnes, ligne)
        var ligneHTML =   `
        <tr class="ligne">
        ${contentColonnesHTML}
        </tr>
        `
        contentRow.innerHTML += ligneHTML
    }
}

function concatenerColonnesLigneContenu(colonnes, ligne) {
    var colonneHTML = ``
    for(var colonne of colonnes) {
        if(ligne === undefined) {
            var colonneInnerHTML = ajoutNewInnerHTML(colonne)
        } else {
            var colonneInnerHTML = ajoutInnerHTML(colonne, ligne)
        }
        colonneHTML +=  `
                            <td class="colonne">
                                ${colonneInnerHTML}
                            </td>\n
                        `
    }
    return colonneHTML
}

function ajoutInnerHTML(colonne, ligne) {
    var result = ``
    switch(colonne["type"]) {
        case "collection":
            var options = ``
            for(var option of colonne["options"]) {
                options += `<option>${option.replace(/[_]+/g, " ")}</option>\n`
            }
            result +=   `
                            <select name="${(colonne["name"] === undefined) ? "" : colonne["name"]}" value="${ligne.find(o => o.nom === colonne["nom"])["result"]}">
                                <option style="display: none;" selected>${ligne.find(o => o.nom === colonne["nom"])["result"].replace(/[_]+/g, " ")}</option>\n
                                ${options}
                            </select>
                        `
            break;
        case "input":
            result +=   `
                            <input name="${(colonne["name"] === undefined) ? "" : colonne["name"]}" value="${ligne.find(o => o.nom === colonne["nom"])["result"]}" />
                        `
            break;
        case "input:number":
            result +=   `
                            <input type="number" name="${(colonne["name"] === undefined) ? "" : colonne["name"]}" value="${ligne.find(o => o.nom === colonne["nom"])["result"]}" />
                        `
            break; 
        default:
            result += `${ligne.find(o => o.nom === colonne["nom"])["result"]}`
            break; 
    }
    return result
}

function ajoutNewInnerHTML(colonne) {
    var result = ``
    switch(colonne["type"]) {
        case "collection":
            var options = ``
            for(var option of colonne["options"]) {
                options += `<option>${option.replace(/[_]+/g, " ")}</option>\n`
            }
            result +=   `
                            <select name="${(colonne["name"] === undefined) ? "" : colonne["name"]}" value="">
                                ${options}
                            </select>
                        `
            break;
        case "input":
            result +=   `
                            <input name="${(colonne["name"] === undefined) ? "" : colonne["name"]}" value="" />
                        `
            break; 
        case "input:number":
            result +=   `
                            <input type="number" name="${(colonne["name"] === undefined) ? "" : colonne["name"]}" value="" />
                        `
            break; 
        default:
            result += ``
            break; 
    }
    return result
}

function initializeActionBtns(tableau_layout, tableau_init_data) {
    let footer_action_btns =    `<tr>
                                    ${generateActionTableLine(tableau_layout)}
                                </tr>`
    tableau_layout.getElementsByClassName('tableauActionBtn')[0].innerHTML += footer_action_btns
    let addRow = tableau_layout.getElementsByClassName("addRow")[0];
    let removeRow = tableau_layout.getElementsByClassName("removeRow")[0];
    let saveTableBtn = tableau_layout.getElementsByClassName("saveTableBtn")[0];

    addRow.addEventListener('click', (event) => {
        ajouterLigneContenu(tableau_layout, tableau_init_data);
    })
    removeRow.addEventListener('click', (event) => {
        retirerLigneContenu(tableau_layout, tableau_init_data);
    })
    saveTableBtn.addEventListener('click', (event) => {
        sauvegarderLigneContenu(tableau_layout, tableau_init_data);
    })
}

function ajouterLigneContenu(tableau_layout, tableau_init_data) {
    var colonnes = tableau_init_data["colonnes"];
    var contentRow = tableau_layout.getElementsByClassName('tabl_contenu')[0];
    var contentColonnesHTML = concatenerColonnesLigneContenu(colonnes, undefined);
    var ligneHTML = `<tr class="ligne">
                        ${contentColonnesHTML}
                    </tr>`;
    
    // Créez un nouvel élément de ligne et ajoutez-le à la fin du tableau
    var newRow = document.createElement('tr');
    newRow.classList.add('ligne');
    newRow.innerHTML = ligneHTML;
    contentRow.appendChild(newRow);
}

function retirerLigneContenu(tableau_layout) {
    var contentRow = tableau_layout.getElementsByClassName('tabl_contenu')[0]
    contentRow.removeChild(contentRow.lastChild);
}

function sauvegarderLigneContenu(tableau_layout, tableau_init_data) {
    var lignes = tableau_layout.getElementsByClassName('tabl_contenu')[0].children;
    var colonnesHEAD = tableau_init_data["colonnes"];
    var result = [];
    for(var ligne of lignes) {
        var ligne_result = [];
        for(var i = 0; i < ligne.children.length; i++) {
            var colonneHEAD = colonnesHEAD[i];
            var colonneLigne = ligne.children[i];
            ligne_result.push({ nom: `${colonneHEAD.nom.replace(/ /g, '_')}`, result: `${colonneLigne.children[0].value.replace(/ /g, '_')}` });
        }
        result.push(ligne_result);
    }
    let tableau_final_data = tableau_init_data["lignes"] = result;
    tableau_layout.setAttribute('data-tableau-hex', JSON.stringify(tableau_final_data));
    let awnser_input = tableau_layout.getElementsByClassName('saver_input_tableau')[0];
    awnser_input.value = JSON.stringify(tableau_final_data)
}

export { initHEXTableauDynamique };