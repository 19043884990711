import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["content"];

    connect() {
        this.contentTarget.style.maxHeight = "0px";
        this.contentTarget.style.overflow = "hidden";
        this.expanded = false;
    }

    toggle() {
        if (this.expanded) {
            this.collapse();
        } else {
            this.expand();
        }
    }

    expand() {
        this.contentTarget.style.maxHeight = this.contentTarget.scrollHeight + "px";
        this.expanded = true;
    }

    collapse() {
        this.contentTarget.style.maxHeight = "0px";
        this.expanded = false;
    }
}
