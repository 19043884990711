const initPanel = () => {
    const openPanelBtn = document.getElementById('open-panel-btn');
    const panel = document.getElementById('bottom-panel');
    const overlay2 = document.getElementById('overlay2');

    if (!openPanelBtn || !panel || !overlay2) {
        return;
    }
    const togglePanel = () => {
        panel.classList.toggle('active');
        overlay2.classList.toggle('active');
    };

    openPanelBtn.addEventListener('click', togglePanel);

    overlay2.addEventListener('click', togglePanel);

    document.addEventListener('click', (event) => {
        if (!panel.contains(event.target) && event.target !== openPanelBtn) {
            panel.classList.remove('active');
            overlay2.classList.remove('active');
        }
    });
}
export { initPanel };