const initToggleEyes = () => {
    const toggleEye = document.querySelector('.toggle-password');

    if (toggleEye) {
        toggleEye.addEventListener('click', () => {
            const fieldsToToggle = toggleEye.getAttribute('toggle').split(',');

            fieldsToToggle.forEach(fieldId => {
                const passwordField = document.querySelector(fieldId.trim());

                if (passwordField) {
                    const type = passwordField.getAttribute('type') === 'password' ? 'text' : 'password';
                    passwordField.setAttribute('type', type);
                }
            });

            toggleEye.classList.toggle('fa-eye');
            toggleEye.classList.toggle('fa-eye-slash');
        });
    }
};

export { initToggleEyes };
