import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import FileUpload from './FileUpload';
import ModelSelection from './ModelSelection';
import ColumnMapping from './ColumnMapping';

const ImportWizard = ({ models, juniorId }) => {
    const [step, setStep] = useState(1);
    const [file, setFile] = useState(null);
    const [model, setModel] = useState('');
    const [columns, setColumns] = useState([]);

    const handleFileUpload = (uploadedFile) => {
        setFile(uploadedFile);
        setStep(2);
    };

    const handleModelSelection = (selectedModel) => {
        setModel(selectedModel);
        setColumns(models[selectedModel]);
        setStep(3);
    };

    useEffect(() => {
        document.querySelector('.page_content').classList.add('import-wizard-background');
        return () => {
            document.querySelector('.page_content').classList.remove('import-wizard-background');
        };
    }, []);

    return (
        <div className="import-page">
            <div className="header">
                <h1>Import de données - {step === 1 ? 'Fichier' : step === 2 ? 'Modèle' : 'Rapprochement des colonnes'}</h1>
                <div className="step-indicator">
                    <div className={`step ${step === 1 ? 'active' : ''}`}></div>
                    <div className={`step ${step === 2 ? 'active' : ''}`}></div>
                    <div className={`step ${step === 3 ? 'active' : ''}`}></div>
                </div>
            </div>
            <div className="import-wizard">
                {step === 1 && <FileUpload onNext={handleFileUpload} />}
                {step === 2 && <ModelSelection onNext={handleModelSelection} models={Object.keys(models)} />}
                {step === 3 && <ColumnMapping file={file} model={model} columns={columns} juniorId={juniorId} />}
            </div>
        </div>
    );
};

ImportWizard.propTypes = {
    models: PropTypes.objectOf(
        PropTypes.arrayOf(PropTypes.string)
    ).isRequired,
    juniorId: PropTypes.number.isRequired,
};

export default ImportWizard;
