import React, { useState } from 'react';

const FileUpload = ({ onNext }) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleFileChange = (event) => {
        onNext(event.target.files[0]);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragging(false);
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            onNext(event.dataTransfer.files[0]);
        }
    };

    return (
        <div
            className="file-upload"
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
        >
            {isDragging && (
                <div className="drop-indicator">
                    Déposez votre fichier
                </div>
            )}
            <div className="upload-area">
                <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="#ffffff"
                              d="M128 64c0-35.3 28.7-64 64-64H352V128c0 17.7 14.3 32 32 32H512V448c0 35.3-28.7 64-64 64H192c-35.3 0-64-28.7-64-64V336H302.1l-39 39c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l39 39H128V64zm0 224v48H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H128zM512 128H384V0L512 128z"/>
                    </svg>
                </div>
                <p>Déposez votre fichier juste ici (xlsx, csv)</p>
                <button onClick={() => document.getElementById('fileInput').click()}>Sélectionner un fichier</button>
                <input id="fileInput" type="file" accept=".csv, .xlsx" onChange={handleFileChange} style={{ display: 'none' }} />
            </div>
        </div>
    );
};

export default FileUpload;
