const initColonerCat = () => {
    const categorySelector = document.getElementById('categorySelector');
    if(!categorySelector) {
        return;
    }
    const categories = document.querySelectorAll('.colonner-cat');


    categorySelector.addEventListener('change', (event) => {
        const selectedCategory = event.target.value;

        categories.forEach(cat => {
            if (cat.id === selectedCategory) {
                cat.style.display = 'flex';
            } else {
                cat.style.display = 'none';
            }
        });
    });
};

export { initColonerCat };
