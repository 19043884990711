import Sortable from 'sortablejs';

const initSortable = () => {
    const container = document.getElementById('chapters-container');
    if (container) {
        Sortable.create(container, {
            animation: 150,
            onEnd: (event) => {
                const id = event.item.dataset.id;
                const newIndex = event.newIndex + 1;
                const url = `/juniors/${container.dataset.juniorId}/courses/${container.dataset.courseId}/chapters/${id}/move`;

                fetch(url, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                    },
                    body: JSON.stringify({ position: newIndex }),
                }).then(() => {
                    const chapters = container.querySelectorAll('.chapter');
                    chapters.forEach((chapter, index) => {
                        chapter.querySelector('.chapter-position').textContent = `Chapitre ${index + 1}`;
                    });
                });
            },
        });
    }
    const lessonElementsContainer = document.querySelector('.lesson-elements-container');

    if (lessonElementsContainer) {
        const juniorId = lessonElementsContainer.dataset.juniorId;
        const courseId = lessonElementsContainer.dataset.courseId;
        const chapterId = lessonElementsContainer.dataset.chapterId;
        const lessonId = lessonElementsContainer.dataset.lessonId;

        Sortable.create(lessonElementsContainer, {
            animation: 150,
            handle: '.drag-handle',
            onEnd: (event) => {
                const element = event.item;
                const newIndex = event.newIndex;

                // Send AJAX request to update the position of the lesson element
                const lessonElementId = element.dataset.lessonElementId;
                const url = `/juniors/${juniorId}/courses/${courseId}/chapters/${chapterId}/lessons/${lessonId}/lesson_elements/${lessonElementId}/update_position`;
                const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

                fetch(url, {
                    method: 'PATCH',
                    headers: {
                        'X-CSRF-Token': token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ position: newIndex + 1 })
                });
            }
        });
    }
        const lessonsContainer = document.querySelector('.lessons-container');

        if (lessonsContainer) {
            Sortable.create(lessonsContainer, {
                animation: 150,
                handle: '.lesson-card',
                onEnd: (event) => {
                    const order = Array.from(lessonsContainer.children).map((lessonCard, index) => ({
                        id: lessonCard.dataset.lessonId,
                        position: index + 1
                    }));

                    // Send AJAX request to update the position of the lessons
                    const juniorId = lessonsContainer.dataset.juniorId;
                    const courseId = lessonsContainer.dataset.courseId;
                    const chapterId = lessonsContainer.dataset.chapterId;
                    const url = `/juniors/${juniorId}/courses/${courseId}/chapters/${chapterId}/update_lesson_positions`;
                    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

                    fetch(url, {
                        method: 'PATCH',
                        headers: {
                            'X-CSRF-Token': token,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ order })
                    }).then(response => {
                        if (response.ok) {
                            console.log('Positions updated successfully');
                        } else {
                            console.error('Failed to update positions');
                        }
                    });
                }
            });
        }

};

export { initSortable };
