const initAborescence = () => {
    const treeNodes = document.querySelectorAll('.tree-node');

    treeNodes.forEach(node => {
        node.addEventListener('click', (event) => {
            const link = node.querySelector('a.tree-link');
            if (link) {
                window.location.href = link.href;
            }
        });
    });

    // Prevent propagation of click events from internal links
    const internalLinks = document.querySelectorAll('.tree-node a');
    internalLinks.forEach(link => {
        link.addEventListener('click', (event) => {
            event.stopPropagation();
        });
    });
}

export { initAborescence };