import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import ImportStatusModal from './ImportStatusModal';

const ColumnMapping = ({ file, model, columns, juniorId }) => {
    const [mappings, setMappings] = useState({});
    const [fileColumns, setFileColumns] = useState([]);
    const [fileData, setFileData] = useState([]);
    const [fileRawData, setFileRawData] = useState(null);
    const [importJobId, setImportJobId] = useState(null);

    useEffect(() => {
        const readFile = () => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
                const columns = worksheet[0];
                const dataRows = worksheet.slice(1, 6); // Display first 5 rows of data

                setFileColumns(columns);
                setFileData(dataRows.map(row => row.map(cell => {
                    if (typeof cell === 'number') {
                        const date = XLSX.SSF.parse_date_code(cell);
                        if (date) {
                            const jsDate = new Date(Date.UTC(date.y, date.m - 1, date.d, date.H, date.M, date.S));
                            const year = jsDate.getUTCFullYear();
                            if (year >= 1990 && year <= 2050) {
                                return jsDate.toLocaleDateString();
                            }
                        }
                    }
                    return cell;
                })));
                setFileRawData(worksheet);

                const initialMappings = {};
                columns.forEach(column => {
                    initialMappings[column] = '';
                });
                setMappings(initialMappings);
            };
            reader.readAsArrayBuffer(file);
        };

        readFile();
    }, [file]);

    const handleMappingChange = (fileColumn, event) => {
        setMappings({
            ...mappings,
            [fileColumn]: event.target.value
        });
    };

    const getAvailableOptions = (currentColumn) => {
        const selectedColumns = Object.values(mappings).filter(value => value !== '' && value !== mappings[currentColumn]);
        return columns.filter(column => !selectedColumns.includes(column));
    };

    const handleSubmit = () => {
        const mappedData = fileRawData.map(row => {
            const newRow = {};
            Object.keys(mappings).forEach(fileColumn => {
                const dbColumn = mappings[fileColumn];
                if (dbColumn) {
                    newRow[dbColumn] = row[fileColumns.indexOf(fileColumn)];
                }
            });
            return newRow;
        });

        const headers = Object.values(mappings).filter(value => value !== '');
        const data = mappedData.map(row => headers.map(header => row[header]));

        const payload = {
            junior_id: juniorId,
            model_name: model,
            data: [headers, ...data]
        };

        fetch(`/juniors/${juniorId}/jestion_data/process_data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                setImportJobId(data.job_id);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <div className="column-mapping">
            <h2>Colonnes</h2>
            <table>
                <thead>
                <tr>
                    {fileColumns.map((column, index) => (
                        <th key={index}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span>{column}</span>
                                <span style={{ margin: '0 10px' }}>→</span>
                                <select onChange={(e) => handleMappingChange(column, e)} value={mappings[column]}>
                                    <option value="">Choisissez la donnée</option>
                                    {getAvailableOptions(column).map(dbColumn => (
                                        <option key={dbColumn} value={dbColumn}>{dbColumn}</option>
                                    ))}
                                </select>
                            </div>
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {fileData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
            <button onClick={handleSubmit}>Importer mes données</button>
            {importJobId && <ImportStatusModal jobId={importJobId} onClose={() => setImportJobId(null)} juniorId={juniorId} />}
        </div>
    );
};

export default ColumnMapping;
