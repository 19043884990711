import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import QcmComponent from './QcmComponent';
import YouTubeVideo from './YouTubeVideo';


const LessonContent = ({ lessonElements, junior, current_user }) => {
    const [depotAnswers, setDepotAnswers] = useState({});
    const videoRefs = useRef({});
    const fileInputRefs = useRef({});

    const fetchDepotAnswers = async (lessonElementId) => {

        const response = await fetch(`/juniors/${junior.current_junior}/depot_answers?user_id=${current_user}&lesson_element_id=${lessonElementId}`);
        const data = await response.json();
        setDepotAnswers(prevState => ({ ...prevState, [lessonElementId]: data }));
    };

    const handleFileSubmit = async (event, lessonElementId) => {
        event.preventDefault();
        const file = fileInputRefs.current[lessonElementId].files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('user_id', current_user);
        formData.append('lesson_element_id', lessonElementId);

        const response = await fetch(`/juniors/${junior.current_junior}/depot_answers`, {
            method: 'POST',
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: formData
        });
        fetchDepotAnswers(lessonElementId);
    };

    useEffect(() => {
        lessonElements.forEach(element => {

            if (element.element_type === 'Dépot de fichier') {
                fetchDepotAnswers(element.id);
            } else if (element.element_type === 'Vidéo') {

            }
        });
    }, [lessonElements]);




    return (
        <div className="lesson-content">
            {lessonElements.map((element) => (
                <div key={element.id} className={`lesson-element ${element.element_type.toLowerCase()}`} >
                    {renderElementContent(element, videoRefs, fileInputRefs, depotAnswers, handleFileSubmit, junior, current_user)}
                </div>
            ))}
        </div>
    );
};

const renderElementContent = (element, videoRefs, fileInputRefs, depotAnswers, handleFileSubmit, junior, current_user) => {
    switch (element.element_type) {
        case 'Titre':
            return <div className="titre">{element.content}</div>;
        case 'Texte':
            return <p className="paragraphe">{element.content}</p>;
        case 'Vidéo':
            return (
                <YouTubeVideo
                    element={element}
                    user={current_user}
                    junior={junior}
                />
            )
        case 'Image':
            return (
                <div className="image-container">
                    <img src={element.picture} alt="Content Picture"/>
                </div>
            );
        case 'Fichier':
            return (
                <div className="download-container">
                    <a href={element.download} target="_blank" rel="noopener noreferrer">
                        <button>Télécharger le fichier</button>
                    </a>
                </div>
            );



        case 'Dépot de fichier':
            const depotAnswer = depotAnswers[element.id];
            const validationDic = {
                pending: 'En attente de validation',
                refused: 'Refusé',
                accepted: 'Accepté',
            }
            if (depotAnswer && depotAnswer.length > 0) {
                const sortedDepotAnswers = depotAnswer.sort((a, b) => new Date(b.created_at) -new Date(a.created_at));
                const pendingAnswer = sortedDepotAnswers.find(depot => depot.validation === 'pending');
                const refusedAnswer = sortedDepotAnswers.find(depot => depot.validation === 'refused');
                const acceptedAnswer = sortedDepotAnswers.find(depot => depot.validation === 'accepted');
                if (acceptedAnswer) {
                    return (
                        <div className="depot-fichier-container">
                            <p className="consigne">{element.content}</p>
                            <p>Votre dernier document déposé a été validé !</p>
                            <h4>Historique des dépôts :</h4>
                            <table className="depot-history-table">
                                <thead>
                                <tr>
                                    <th>Nom du fichier</th>
                                    <th>Validation</th>
                                </tr>
                                </thead>
                                <tbody>
                                {depotAnswer.map((depot, index) => (
                                    <tr key={index}>
                                        <td><a href={depot.file_url} target="_blank"
                                               rel="noopener noreferrer">{depot.file_name}</a></td>
                                        <td>{validationDic[depot.validation]}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    );
                } else if (pendingAnswer) {
                    return (
                        <div className="depot-fichier-container">
                            <p className="consigne">{element.content}</p>
                            <p>Votre dernier document déposé est en attente de validation.</p>
                            <h4>Historique des dépôts :</h4>
                            <table className="depot-history-table">
                                <thead>
                                <tr>
                                    <th>Nom du fichier</th>
                                    <th>Validation</th>
                                </tr>
                                </thead>
                                <tbody>
                                {depotAnswer.map((depot, index) => (
                                    <tr key={index}>
                                        <td><a href={depot.file_url} target="_blank"
                                               rel="noopener noreferrer">{depot.file_name}</a></td>
                                        <td>{validationDic[depot.validation]}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    );
                } else if (refusedAnswer) {
                    return (
                        <div className="depot-fichier-container">
                            <p className="consigne">{element.content}</p>

                            <p>Votre document a été refusé. Veuillez déposer un nouveau fichier.</p>
                            <form onSubmit={(event) => handleFileSubmit(event, element.id)}>
                                <input type="file" ref={el => fileInputRefs.current[element.id] = el}/>
                                <button type="submit">Déposer un nouveau fichier</button>
                            </form>
                            <h4>Historique des dépôts :</h4>
                            <table className="depot-history-table">
                                <thead>
                                <tr>
                                    <th>Nom du fichier</th>
                                    <th>Validation</th>
                                </tr>
                                </thead>
                                <tbody>
                                {depotAnswer.map((depot, index) => (
                                    <tr key={index}>
                                        <td><a href={depot.file_url} target="_blank"
                                               rel="noopener noreferrer">{depot.file_name}</a></td>
                                        <td>{validationDic[depot.validation]}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    );
                } else {
                    return (
                        <div className="depot-fichier-container">
                            <p className="consigne">{element.content}</p>

                            <h4>Historique des dépôts :</h4>
                            <table className="depot-history-table">
                                <thead>
                                <tr>
                                    <th>Nom du fichier</th>
                                    <th>Validation</th>
                                </tr>
                                </thead>
                                <tbody>
                                {depotAnswer.map((depot, index) => (
                                    <tr key={index}>
                                        <td><a href={depot.file_url} target="_blank"
                                               rel="noopener noreferrer">{depot.file_name}</a></td>
                                        <td>{validationDic[depot.validation]}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            <form onSubmit={(event) => handleFileSubmit(event, element.id)}>
                                <input type="file" ref={el => fileInputRefs.current[element.id] = el}/>
                                <button type="submit">Déposer un nouveau fichier</button>
                            </form>
                        </div>
                    );
                }
            } else {
                return (
                    <div className="depot-fichier-container">
                        <p className="consigne">{element.content}</p>
                        <form onSubmit={(event) => handleFileSubmit(event, element.id)}>
                            <input type="file" ref={el => fileInputRefs.current[element.id] = el}/>
                            <button type="submit">Déposer le fichier</button>
                        </form>
                    </div>
                );
            }
        case 'QCM':
            return (
                <QcmComponent element={element} junior={junior} current_user={current_user} qcmId={element.id} />
            );
        default:
            return <div>{element.element_type}: {element.content}</div>;
    }
};

LessonContent.propTypes = {
    junior: PropTypes.shape({
        current_junior: PropTypes.number.isRequired,
    }).isRequired,
    current_user: PropTypes.number.isRequired,
    lessonElements: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            element_type: PropTypes.string.isRequired,
            content: PropTypes.string,
            youtube_url: PropTypes.string,
            download: PropTypes.string,
        })
    ).isRequired,
};

export default LessonContent;
