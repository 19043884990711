import React from 'react';
import PropTypes from 'prop-types';
import LessonBlock from './LessonBlock';

const ProgressBar = ({ chapters, currentLesson, lessonProgresses, onLessonClick, leftIconUrl, rightIconUrl }) => {
    return (
        <div className="progress-bar-container">
            <div className="round-button">
                <img src={leftIconUrl} alt="Left Icon" />
            </div>
            <div className="progress-bar">
                {chapters.map((chapter, chapterIndex) => (
                    <div key={chapter.id} className="chapter-block">
                        {chapter.lessons.map((lesson, lessonIndex) => (
                            <LessonBlock
                                key={lesson.id}
                                lesson={lesson}
                                isCurrent={currentLesson.id === lesson.id}
                                progress={lessonProgresses[lesson.id] || 0}
                                onClick={() => onLessonClick(lesson)}
                            />
                        ))}
                    </div>
                ))}
            </div>
            <div className="round-button">
                <img src={rightIconUrl} alt="Right Icon" />
            </div>
        </div>
    );
};

ProgressBar.propTypes = {
    chapters: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            lessons: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    title: PropTypes.string.isRequired,
                }).isRequired
            ).isRequired,
        }).isRequired
    ).isRequired,
    currentLesson: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    onLessonClick: PropTypes.func.isRequired,
    lessonProgresses: PropTypes.object.isRequired,
    leftIconUrl: PropTypes.string.isRequired,
    rightIconUrl: PropTypes.string.isRequired,
};

export default ProgressBar;
