const initDetectEndNavbar = () => {
    var navbar = document.querySelector('.navbar');
    if (navbar) {
        var notificationFlash = document.querySelector('.notification-part');
        if (notificationFlash) {
            var navbarHeight = navbar.offsetHeight;
            notificationFlash.style.top = (navbarHeight + 3) + 'px';
        }
    }

}
export { initDetectEndNavbar };