const initChapterPageLoadAnimations = () => {
    const bannerContainer = document.querySelector('.banner-container');
    const creatorLogoContainer = document.querySelector('.creator-logo-container');
    const courseDescription = document.querySelector('.course-description');
    const buttonContainer = document.querySelector('.button-container');
    const chapters = document.querySelectorAll('.chapter');

    if (bannerContainer) {
        bannerContainer.classList.add('visible');
    }

    if (creatorLogoContainer) {
        creatorLogoContainer.classList.add('visible');
    }

    if (courseDescription) {
        courseDescription.classList.add('visible');
    }

    if (buttonContainer) {
        buttonContainer.classList.add('visible');
    }

    if (chapters) {
        chapters.forEach((chapter, index) => {
            setTimeout(() => {
                chapter.classList.add('visible');
            }, index * 100); // Stagger the chapter animations
        });
    }
};

export { initChapterPageLoadAnimations };
