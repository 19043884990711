const initTableauHexSearchBar = () => {
    const tabs = document.querySelectorAll('.tabs .tab');
    const contents = document.querySelectorAll('.contents .content');
    var debounceTimeout = null;
    var debounceDelay = 800;
    const setActiveTab = (index) => {
        tabs.forEach((tab, tabIndex) => {
            if (tabIndex === index) {
                tab.classList.add('active');
            } else {
                tab.classList.remove('active');
            }
        });

        contents.forEach((content, contentIndex) => {
            if (contentIndex === index) {
                content.classList.add('active');
            } else {
                content.classList.remove('active');
            }
        });
    };

    tabs.forEach((tab, index) => {
        tab.addEventListener('click', () => {
            setActiveTab(index);
            const currentUrl = new URL(window.location);
            currentUrl.searchParams.set('active_tab', index);
            window.history.replaceState({}, '', currentUrl);
        });
    });

    const input1 = document.getElementById("tableau_search_bar");
    const input2 = document.getElementById("tableau_search_bar2");

    const inputHandler = (input, searchParam) => {
        if (!input) return;
        input.focus();
        let lastSearchTerm = new URLSearchParams(window.location.search).get(searchParam) || '';
        input.value = lastSearchTerm;

        const sendSearchQuery = () => {
            const searchQuery = input.value;
            if (searchQuery !== lastSearchTerm) {
                lastSearchTerm = searchQuery;

                const currentUrl = new URL(window.location);
                const searchParams = new URLSearchParams(currentUrl.search);

                searchParams.set(searchParam, searchQuery);
                const activeTab = searchParams.get('active_tab') || 0;
                searchParams.set('page' + activeTab, 1);

                const newUrl = `${currentUrl.pathname}?${searchParams.toString()}`;
                window.location.href = newUrl;
            }
        };

        const onInputEvent = () => {
            clearTimeout(debounceTimeout);
            debounceTimeout = setTimeout(sendSearchQuery, debounceDelay);
        };

        const onEnterOrBlur = (event) => {
            if (event.type === 'blur' || event.key === 'Enter') {
                clearTimeout(debounceTimeout);
                sendSearchQuery();
            }
        };
        input.addEventListener('input', onInputEvent);
        input.addEventListener('blur', onEnterOrBlur);
        input.addEventListener('keyup', onEnterOrBlur);
    };

    inputHandler(input1, 'search1');
    inputHandler(input2, 'search2');

    const activeTabIndex = parseInt(new URLSearchParams(window.location.search).get('active_tab')) || 0;
    setActiveTab(activeTabIndex);
};

export { initTableauHexSearchBar };
