const initLessonQCM = () => {


    const qcmBuilder = document.getElementById('qcm-builder');
    const elementTypeInput = document.getElementById('element_type');
    const formElements = document.querySelectorAll('.formulaire_input.item_to_choose');
    const hasPotentialQcmDivs = document.querySelectorAll('.formulaire_input.item_to_choose.qcm');

    if (hasPotentialQcmDivs.length > 0) {
        const checkClasses = () => {
            const allPotentialQcmDivs = document.querySelectorAll('.formulaire_input.item_to_choose.qcm');
            const qcmDivHidden = Array.from(allPotentialQcmDivs).filter(element =>
                element.className === 'formulaire_input item_to_choose qcm'
            );
            if (qcmBuilder && qcmDivHidden.length > 0) {
                const addQuestionButton = document.getElementsByClassName("btn btn-secondary add-question hidden")[0];
                if (addQuestionButton) {
                    addQuestionButton.classList.remove('hidden');
                }
                const updateQCMContent = () => {
                    const questions = [];
                    document.querySelectorAll('.qcm-question').forEach((questionElement, questionIndex) => {
                        const question = questionElement.querySelector('.question-input').value;
                        const answers = [];
                        questionElement.querySelectorAll('.answer-input').forEach((answerElement, answerIndex) => {
                            const text = answerElement.value;
                            const correct = answerElement.nextElementSibling.textContent === 'Correcte';
                            answers.push({text, correct});
                        });
                        questions.push({question, answers});
                    });

                    let qcmContentField = document.querySelector('input.qcm-content');
                    if (!qcmContentField) {
                        qcmContentField = document.createElement('input');
                        qcmContentField.setAttribute('type', 'hidden');
                        qcmContentField.setAttribute('name', 'lesson_element[content]');
                        qcmContentField.setAttribute('class', 'qcm-content');
                        document.querySelector('form').appendChild(qcmContentField);
                    }
                    qcmContentField.value = JSON.stringify(questions);
                };

                document.querySelector('.add-question').addEventListener('click', (e) => {
                    e.preventDefault();
                    const questionCount = document.querySelectorAll('.qcm-question').length;
                    const newQuestionHTML = `
                    <div class="qcm-question" data-index="${questionCount}">
                        <div class="form-group">
                            <label for="lesson_element_content_questions_${questionCount}_question">Question ${questionCount + 1}</label>
                            <input type="text" class="form-control question-input" />
                        </div>
                        <div class="answers-container">
                            <div class="form-group">
                                <label for="lesson_element_content_questions_${questionCount}_answers_0_text">Réponse 1</label>
                                <input type="text" class="form-control answer-input" />
                                <button type="button" class="btn btn-secondary toggle-correct-answer" style="margin-top: 10px; padding: 5px 10px; background-color: #f44336; color: white; border: none; border-radius: 5px;">Incorrecte</button>
                            </div>
                        </div>
                        <button type="button" class="btn btn-sm btn-secondary add-answer" style="margin-top: 10px; padding: 5px; background-color: #4CAF50; color: white; border: none; border-radius: 5px;" data-question-index="${questionCount}">Ajouter une réponse</button>
                    </div>
                `;
                    document.querySelector('.qcm-questions').insertAdjacentHTML('beforeend', newQuestionHTML);
                });

                qcmBuilder.addEventListener('click', (e) => {
                    if (e.target.classList.contains('add-answer')) {
                        e.preventDefault();
                        const questionIndex = e.target.dataset.questionIndex;
                        const questionElement = document.querySelector(`.qcm-question[data-index="${questionIndex}"] .answers-container`);
                        const answerCount = questionElement.children.length;
                        const newAnswerHTML = `
                        <div class="form-group">
                            <label for="lesson_element_content_questions_${questionIndex}_answers_${answerCount}_text">Réponse ${answerCount + 1}</label>
                            <input type="text" class="form-control answer-input" />
                            <button type="button" class="btn btn-secondary toggle-correct-answer" style="margin-top: 10px; padding: 5px 10px; background-color: #f44336; color: white; border: none; border-radius: 5px;">Incorrecte</button>
                        </div>
                    `;
                        questionElement.insertAdjacentHTML('beforeend', newAnswerHTML);
                    }

                    if (e.target.classList.contains('toggle-correct-answer')) {
                        e.preventDefault();
                        const button = e.target;
                        const isCorrect = button.textContent === 'Correcte';
                        button.textContent = isCorrect ? 'Incorrecte' : 'Correcte';
                        button.style.backgroundColor = isCorrect ? '#f44336' : '#4CAF50';
                    }
                });

                document.querySelector('form').addEventListener('submit', updateQCMContent);
            }
        };

        const showRelevantField = () => {
            const selectedType = elementTypeInput.value;

            formElements.forEach(element => {

                if (element.getAttribute('name') === selectedType) {
                    element.classList.remove('hidden');
                    if (selectedType !== 'QCM' && !element.querySelector('.form_input')) {
                        const contentField = createContentField(selectedType);
                        element.appendChild(contentField);
                    }
                } else {
                    if (element === selectedType) {
                        element.classList.remove('hidden');
                    } else {
                        element.classList.add('hidden');
                        const inputField = element.querySelector('.form_input');
                        if (inputField) {
                            element.removeChild(inputField);
                        }
                    }
                }
            });
        };

        const createContentField = (type) => {
            let contentField;
            if (type === 'Vidéo') {

            } else {
                contentField = document.createElement('textarea');
                contentField.setAttribute('type', 'text');
                contentField.setAttribute('name', 'lesson_element[content]');
                contentField.setAttribute('class', 'form_input');
                contentField.setAttribute('placeholder', 'Texte de l\'élément');
            }
            return contentField;
        };

        checkClasses();
        elementTypeInput.addEventListener('change', () => {
            checkClasses();
            showRelevantField();
        });

        showRelevantField();
    }
};

export { initLessonQCM };
