const initDropzone = () => {
    const dropzone = document.getElementById('dropzone');
    const preview = document.getElementById('preview');
    const fileInput = document.getElementById('fileupload');
    let filesArray = [];

    const handleFiles = (files) => {
        Array.from(files).forEach(file => {
            filesArray.push(file);
            const p = document.createElement('p');
            p.textContent = file.name;
            preview.appendChild(p);
        });
    };

    if (dropzone) {
        dropzone.addEventListener('dragover', (e) => {
            e.preventDefault();
            e.stopPropagation();
            dropzone.classList.add('dragging');
        });

        dropzone.addEventListener('dragleave', (e) => {
            e.preventDefault();
            e.stopPropagation();
            dropzone.classList.remove('dragging');
        });

        dropzone.addEventListener('drop', (e) => {
            e.preventDefault();
            e.stopPropagation();
            dropzone.classList.remove('dragging');
            handleFiles(e.dataTransfer.files);
        });
    }

    // Intercepter l'envoi du formulaire pour ajouter les fichiers à FormData
    const form = document.getElementById('expense-form');
    if (form) {
        form.addEventListener('submit', (e) => {
            e.preventDefault();

            const formData = new FormData(form);
            filesArray.forEach(file => {
                formData.append('expense[proofs][]', file);
            });

            fetch(form.action, {
                method: form.method,
                body: formData,
                headers: {
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                }
            })
                .then(response => {
                    if (response.ok) {
                        window.location.href = response.url;
                    } else {
                        return response.json().then(errors => {
                            console.error('Errors:', errors);
                        });
                    }
                })
                .catch(error => {
                    console.error('Error submitting form:', error);
                });
        });
    }
};

export { initDropzone };
