const initHEXTableauCounter = () => {
    const tableau_counters = document.getElementsByClassName('hexTableauCounter');
    if(tableau_counters.length > 0) {
        for(let tableau_counter of tableau_counters) {
            let count_line = tableau_counter.querySelector('#count_me');
            if(count_line !== undefined) {
                let to_counts = count_line.querySelectorAll("[js-table-counter]");
                for(let to_count of to_counts) {
                    let attribute = to_count.getAttribute('js-table-counter');
                    let lines = tableau_counter.querySelectorAll(`.${attribute}`);
                    var sum = 0;
                    for(let line of lines) {
                        sum += parseFloat(line.innerText);
                    }
                    to_count.innerText = `${Math.round(sum * 100)/100} €`
                }
            }
        }
    }
}

export { initHEXTableauCounter }