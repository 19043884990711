function checkAccessCode(correctCode, url, csrfToken, event) {
    event.preventDefault();
    event.stopPropagation();

    const userCode = prompt("Veuillez entrer le code d'accès pour devenir membre :");

    if (userCode === correctCode) {
        var form = document.createElement('form');
        form.method = 'POST';
        form.action = url;

        var csrfInput = document.createElement('input');
        csrfInput.type = 'hidden';
        csrfInput.name = 'authenticity_token';
        csrfInput.value = csrfToken;
        form.appendChild(csrfInput);

        document.body.appendChild(form);
        form.submit();
    } else {
        alert("Code incorrect");
    }
}

window.checkAccessCode = checkAccessCode;

const initDevenirMembre = () => {
    const devenirMembreButton = document.querySelector('#devenir-membre-btn');

    if (devenirMembreButton) {
        devenirMembreButton.addEventListener('click', (event) => {
            checkAccessCode('VotreCodeSecret', 'VotreURL', 'VotreTokenCSRF', event);
        });
    }
}

export { initDevenirMembre };
