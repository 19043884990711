// YouTubeAPI.js

let isAPILoaded = false;
let loadAPI;

const loadYouTubeAPI = () => {
    if (isAPILoaded) {
        return Promise.resolve();
    }

    if (!loadAPI) {
        loadAPI = new Promise((resolve) => {
            window.onYouTubeIframeAPIReady = () => {
                isAPILoaded = true;
                resolve();
            };

            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            document.head.appendChild(tag);
        });
    }

    return loadAPI;
};

export default loadYouTubeAPI;
