import React from 'react';
import PropTypes from 'prop-types';

const Banner = ({ course, parcour, junior }) => {
    const parcoursUrl = `/juniors/${junior.current_junior}/learning_center`
    return (
        <div className="banner">
            <div className="banner__left">
                <div className="banner__breadcrumb">
                    {course.is_a_parcour ? (
                        <>
                            <a href={parcoursUrl}>Parcours &gt; {parcour.title} </a>
                            &gt;&nbsp;
                            {course.title}
                        </>
                    ) : (
                        <>
                            <a href={parcoursUrl}>Learning Center &gt; </a>
                            {course.title}
                        </>
                    )}
                </div>
                <div className="banner__title">{course.title}</div>
                <div className="banner__details">
                    <span><i className="fas fa-clock"/> {course.time} minutes</span>
                    <span><i className="fa fa-signal"/> {course.difficult}</span>
                </div>
            </div>
            <div className="banner__right">
                <img src={course.creator_logo} alt="Creator Logo"/>
                <div className="banner__right-updated">Dernière mise à jour le {course.last_updated_at}</div>
            </div>
        </div>
    );
};


export default Banner;
