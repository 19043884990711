const initClickableDiv = () => {
    var clickableDiv = document.querySelectorAll('.clickable-div');
    if (clickableDiv.length == 0) return;

    clickableDiv.forEach(function(div) {
        div.addEventListener('click', function() {
            var url = div.dataset.url;
            window.location = url;
        });
    })
}

export { initClickableDiv };