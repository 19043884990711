const initMenuSelector = () => {
    if(document.getElementsByClassName('tab').length > 0) {
        let menu_tabs = document.getElementsByClassName('tab')
        let menu_contents = document.getElementsByClassName('content')

        AccessPageMenuConfig();

        for(let i = 0; i < menu_tabs.length; i++ ) {
            menu_tabs[i].addEventListener('click', (event) => {
                updateMenuTabs(i);
                updateMenuContents(i);
                UpdateMenuCookies(i);
            });
        };

        function updateMenuTabs(i) {
            for(let menu_tab of menu_tabs) {
                menu_tab.classList.remove('active');
            };
            menu_tabs[i].classList.add('active');
        }

        function updateMenuContents(i) {
            for(let menu_content of menu_contents) {
                menu_content.classList.remove('active');
            };
            menu_contents[i].classList.add('active');
        }

        function UpdateMenuCookies(i) {
            document.cookie = `${window.location.href}=${i}`;
        }

        function AccessPageMenuConfig() {
            let page_id = getCookie(window.location.href);
            if(page_id == undefined) {
                page_id = 0
            }
            updateMenuTabs(page_id);
            updateMenuContents(page_id);
        }

        function getCookie(cName) {
            const name = cName + "=";
            const cDecoded = decodeURIComponent(document.cookie); //to be careful
            const cArr = cDecoded .split('; ');
            let res;
            cArr.forEach(val => {
                if (val.indexOf(name) === 0) res = val.substring(name.length);
            })
            return res;
        }
    }
}

export { initMenuSelector };