const initCenterModal = () => {
    const modal = document.getElementById("myModal");
    const btn = document.getElementById("openModalButton");
    const span = document.getElementsByClassName("close")[0];

    if (btn && modal && span) {
        btn.onclick = function() {
            modal.style.display = "flex";
            modal.querySelector('.modal-content').style.transform = 'scale(1)';
        };

        span.onclick = function() {
            modal.querySelector('.modal-content').style.transform = 'scale(0)';
            setTimeout(() => { modal.style.display = "none"; }, 300);
        };

        window.onclick = function(event) {
            if (event.target == modal) {
                modal.querySelector('.modal-content').style.transform = 'scale(0)';
                setTimeout(() => { modal.style.display = "none"; }, 300);
            }
        };
    }
};


export { initCenterModal };