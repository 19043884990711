import Rails from "@rails/ujs";
import ReactOnRails from 'react-on-rails';
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
global.$ = require('jquery');
//= require actiontext

Rails.start();
Turbolinks.start();
ActiveStorage.start();
import "trix"
import "@rails/actiontext"

// Imports externes
import "bootstrap";
import "chartkick/chart.js";


// Imports internes
import { initSelect2 } from '../components/init_select2';
import { initMenuSelector } from "../components/menu_selector";
import { initArrayInput } from "../components/array_input";
import { initHEXModal2 } from "../components/hex_modal2";
import { initTableauHexSearchBar } from "../components/tableau_hex_search_bar";
import { initReducteur } from "../components/reducteur";
import { initBasicMultiple } from "../components/basic_multiple";
import { initDropdown } from "../components/dropdown";
import { initLineChart } from "../components/line_charts";
import { initBarChart } from "../components/barChart";
import { initStackedBarChart } from "../components/stackedBarChart";
import { initHalfDoughnutChart } from "../components/half_doughnut_chart";
import { initQuestionnaireBuilder } from "../components/questionnaire_builder";
import { initHEXTableauDynamique } from "../components/hexTableauDynamique";
import { initHEXTableauCounter } from "../components/hexTableauCounter";
import { initContentPreviewer } from "../components/content_previewer";
import { initGanttV1Chart } from "../components/gantt_v1_chart";
import { initFilter } from "../components/filter";
import { initGantt } from "../components/gantt";
import { initDevenirMembre } from "../components/devenir_membre";
import { initAlert } from "../components/alert";
import { initToggleEyes } from "../components/toggleEyes";
import { initFormsEnter } from "../components/formsEnter";
import { initDetectEndNavbar } from "../components/detectEndNavbar";
import { initDragAndDrop } from "../components/dragAndDropColonner";
import { initColonerCat } from "../components/colonerCat";
import { initCheckAverageClosing } from "../components/checkAverageClosing";
import { initPanel } from "../components/panel";
import { initConnection } from "../components/connection";
import { initCenterModal } from "../components/centerModal";
import { initCourses } from "../components/courses";
import {initSortable} from "../components/sortable";
import {initChapterPageLoadAnimations} from "../components/ChapterPageLoadAnimations";
import {initLessons} from "../components/lessons";
import {initLessonElements} from "../components/lesson_elements";
import {initItemToChoose} from "../components/chooseItem";
import {initLessonQCM} from "../components/lessonQCM";
import {initAborescence} from "../components/initAborescence";
import { initTaskManager} from "../components/taskManager";
import {initClickableDiv} from "../components/clickableDiv";
import {initTracker} from "../components/tracker";
import { initDropzone } from "../components/dropzone";


document.addEventListener('turbolinks:load', () => {
  initSelect2();
  initToggleEyes();
  initMenuSelector();
  initArrayInput();
  initFilter();
  initDevenirMembre();
  initAlert();
  initPanel();
  initConnection();
  initHEXTableauDynamique();
  initGantt();
  initDragAndDrop();
  initHEXModal2();
  initTableauHexSearchBar();
  initReducteur();
  initClickableDiv();
  initBasicMultiple();
  initDropdown();
  initLineChart();
  initColonerCat();
  initCheckAverageClosing();
  initDetectEndNavbar();
  initBarChart();
  initStackedBarChart();
  initHalfDoughnutChart();
  initQuestionnaireBuilder();
  initHEXTableauCounter();
  initContentPreviewer();
  initGanttV1Chart();
  initCourses();
  initAborescence();
  initSortable();
  initChapterPageLoadAnimations();
  initLessons();
  initLessonElements();
  initItemToChoose();
  initLessonQCM();
  initTracker();
  initTaskManager();
  initFormsEnter();
  initCenterModal();
  initBreakout();
  initDropzone();
});


// Internal Import
import HelloWorld from '../bundles/HelloWorld/components/HelloWorld';
import Training from "../bundles/Training/components/Training";
import ImportWizard from "../bundles/Import/components/ImportWizard";



// Register components with ReactOnRails
ReactOnRails.register({
  HelloWorld,
  Training,
  ImportWizard
});


import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import {initBreakout} from "../components/breakout";


const application = Application.start();
const context = require.context("controllers", true, /\.js$/);
application.load(definitionsFromContext(context));
