import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

const initProgressInputs = () => {
    const progressInputs = document.querySelectorAll('.progress-input');

    if (progressInputs.length === 0) return;

    progressInputs.forEach(input => {
        input.removeEventListener('change', validateAndUpdateProgress);
        input.removeEventListener('blur', validateAndUpdateProgress);
        input.addEventListener('change', validateAndUpdateProgress);
        input.addEventListener('blur', validateAndUpdateProgress);
    });
    function validateAndUpdateProgress(event) {
        if (this.value > 100) {
            this.value = 100;
        } else if (this.value < 0) {
            this.value = 0;
        }
        updateProgress.call(this);
    }
    async function updateProgress(event) {
        const progressId = this.getAttribute('data-progress-id');
        const juniorId = this.getAttribute('data-junior-id');
        const parcourId = this.getAttribute('data-parcour-id');
        const progressValue = this.value / 100;

        const response = await fetch(`/juniors/${juniorId}/parcours/${parcourId}/update_lesson_progresses`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({ progress_id: progressId, progress: progressValue })
        });

        if (response.ok) {
            console.log('Progress updated successfully');
        } else {
            console.error('Failed to update progress');
        }
    }
}
const initTracker = () => {
    const viewDetailsButtons = document.querySelectorAll('.view-details');
    const detailsContent = document.getElementById('details-content');

    if (viewDetailsButtons.length > 0 && detailsContent) {
        viewDetailsButtons.forEach(button => {
            button.addEventListener('click', async function () {
                const userId = this.getAttribute('data-user-id');
                const juniorId = this.getAttribute('data-junior-id');
                const parcourId = this.getAttribute('data-parcour-id');

                const response = await fetch(`/juniors/${juniorId}/parcours/${userId}/lesson_progresses?parcour_id=${parcourId}`);
                const data = await response.text();
                detailsContent.innerHTML = data;

                initProgressInputs();
                application.load(definitionsFromContext(context));
                const validationSelects = document.querySelectorAll('.validation-select');

                if (validationSelects.length > 0) {
                    validationSelects.forEach(select => {
                        select.addEventListener('change', updateDepotValidation);
                    });
                }

                async function updateDepotValidation(event) {
                    const depotId = this.getAttribute('data-depot-id');
                    const juniorId = this.getAttribute('data-junior-id');
                    const parcourId = this.getAttribute('data-parcour-id');
                    const validationValue = this.value;

                    const response = await fetch(`/juniors/${juniorId}/parcours/${parcourId}/update_depot_fichier`, {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                        },
                        body: JSON.stringify({ depot_id: depotId, valid: validationValue })
                    });

                    if (response.ok) {
                        console.log('Depot validation updated successfully');
                    } else {
                        console.error('Failed to update depot validation');
                    }
                }
            });
        });
    }

}

export { initTracker };
