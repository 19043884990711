const initCheckAverageClosing = () => {
    const negociationContainer = document.getElementById("negociation-container");
    if (!negociationContainer) {
        return;
    }
    const negociationCards = negociationContainer.querySelectorAll(".etude-card");

    const averageClass = document.getElementsByClassName("average")[0];
    const average = parseFloat(averageClass.getAttribute("data-average"));


    const today = new Date();

    negociationCards.forEach(function(card) {
        const startDate = new Date(card.getAttribute("data-start-date"));
        const differenceInDays = Math.floor((today- startDate) / (1000 * 60 * 60 * 24));
        if (differenceInDays > average) {
            card.classList.add("red-border");
        } else if ((differenceInDays*1.5) > average) {
            card.classList.add("orange-border");
        }
    });

};

export { initCheckAverageClosing };
