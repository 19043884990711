const initTaskManager = () => {
    var arrow = document.getElementById('arrow')

    if (!arrow) {
        return;
    }
    arrow.addEventListener('click', function() {
        document.getElementById('destination').scrollIntoView({behavior: 'smooth'});
    });

    function openModal(modalId, taskId, reference, reference_id, junior_id, campagne_id) {
        closeModals();

        var modal = document.getElementById(modalId);
        if (modalId === 'validActionTaskModal') {


            const url = `/juniors/1/taches/${taskId}/task_details?task_id=${taskId}`;
            fetch(url)
                .then(response => {
                    if (response.ok && response.status !== 204) {
                        return response.json();
                    } else {
                        throw new Error('No content returned from the server');
                    }
                })
                .then(data => {
                    document.getElementById('taskTitle').value = data.title;
                    document.getElementById('taskComment').textContent = data.comment;
                    var button = document.getElementById("customButton");
                    if (reference== "Contact") {
                        button.textContent = "Aller sur le contact";
                        var customLink = "/juniors/" + junior_id + "/contacts/" + reference_id
                        button.setAttribute("href", customLink);
                        button.addEventListener('click', function() {
                            window.location.href = customLink;
                        });
                    }
                    var checkmark = modal.querySelector('.checkmark');
                    checkmark.setAttribute('data-task-id', taskId);
                    modal.style.display = 'flex';
                    modal.querySelector('.modal-content').style.transform = 'scale(1)';
                })
                .catch(error => console.error('Error fetching task details:', error));
        }
    }


    function closeModals() {
        document.querySelectorAll('.modal').forEach(function(modal) {
            modal.style.display = 'none';
        });
    }

    document.querySelectorAll('.close').forEach(function(closeButton) {
        closeButton.addEventListener('click', closeModals);
    });

    window.addEventListener('click', function(event) {
        if (event.target.classList.contains('modal')) {
            closeModals();
        }
    });
    document.getElementById('validateTask').addEventListener('change', function() {
        if (this.checked) {
            const checkmark = this.nextElementSibling;
            const taskId = checkmark.getAttribute('data-task-id');
            const juniorId = checkmark.getAttribute('data-junior-id');

            submitTaskUpdate(taskId, juniorId);
        }
    });
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function submitTaskUpdate(taskId, juniorId) {
        const url = `/juniors/${juniorId}/taches/${taskId}`;
        const data = {
            tache: {
                statut: 'valid',
                date_validation: new Date().toISOString()
            }
        };
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

        fetch(url, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': csrfToken,

            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Problème lors de la mise à jour de la tâche');
                }
            })
            .then(data => {
                console.log('Success:', data);
                sleep(500).then(() => { location.reload(); });
            })
            .catch((error) => {
                console.error('Error:', error);
                alert('Erreur lors de la validation de la tâche');
            });
    }
}

export { initTaskManager };
