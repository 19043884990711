const initContentPreviewer = () => {
    let previewers = document.getElementsByClassName('previewer')
    if(previewers.length > 0) {
        for(let previewer of previewers) {
            let text_area = previewer.querySelector('textarea')
            text_area.addEventListener('keyup', (event) => {
                let value = text_area.value;
                previewer.querySelector('.previewer_area').innerHTML = value;
            })
        }
    }
}

export { initContentPreviewer };