const initCourses = () => {
    const courseCards = document.querySelectorAll('.course-card');

    // Hide all course cards initially
    courseCards.forEach((card, index) => {
        card.style.opacity = 0;
        card.style.transform = "translateY(20px)";
    });

    // Fade in each card with a delay
    courseCards.forEach((card, index) => {
        setTimeout(() => {
            card.style.transition = "all 0.5s ease";
            card.style.opacity = 1;
            card.style.transform = "translateY(0)";
        }, index * 100);
    });

    // Add hover effect to each card
    courseCards.forEach(card => {
        card.addEventListener('mouseover', () => {
            card.style.transform = "translateY(-10px)";
            card.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
        });

        card.addEventListener('mouseout', () => {
            card.style.transform = "translateY(0)";
            card.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
        });
    });


};

export { initCourses };
