const allowDrop = (event) => {
    event.preventDefault();
};

const drag = (event) => {
    event.dataTransfer.setData("text", event.target.id);
};

const drop = (event) => {
    event.preventDefault();
    const data = event.dataTransfer.getData("text");
    const etudeCard = document.getElementById(data);
    const colonne = event.target.closest('.colonner-part');
    const newStatus = colonne.getAttribute('data-status');
    const confirmation = window.confirm('Confirmez-vous la mise à jour de l\'étude ?');


    if (confirmation) {
        colonne.querySelector('.part-content').appendChild(etudeCard);
        updateEtudeStatus(data.split('-')[1], newStatus);
    }
};


const updateEtudeStatus = (etudeId, newStatus) => {


    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const etudeElement = document.getElementById(`etude-${etudeId}`);
    const juniorId = etudeElement.getAttribute('data-junior-id');

    const url = `/juniors/${juniorId}/etudes/${etudeId}`;

    const data = { statut: newStatus };

    console.log(data)
    fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken,

        },
        body: JSON.stringify(data)
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('La mise à jour a échoué');
            }
            return response.json();
        })
        .then(data => {
            window.location.reload();
        })
        .catch(error => {
            console.error('Erreur lors de la mise à jour:', error);
            window.location.reload();

        });
};

const initDragAndDrop = () => {
    if (/Mobi|Android/i.test(navigator.userAgent)) {
        return;
    }

    const etudeCards = document.querySelectorAll('.etude-card');
    const colonnes = document.querySelectorAll('.colonner-part');

    etudeCards.forEach(card => {
        card.setAttribute('draggable', true);
        card.addEventListener('dragstart', drag);
        card.addEventListener('click', (event) => {
            const etudeId = event.currentTarget.id.split('-')[1];
            const juniorId = event.currentTarget.getAttribute('data-junior-id');
            window.location.href = `/juniors/${juniorId}/etudes/${etudeId}`;
        });
    });

    colonnes.forEach(colonne => {
        colonne.addEventListener('dragover', allowDrop);
        colonne.addEventListener('drop', drop);
    });
};


export { initDragAndDrop };
