const initLessons = () => {
    const lessonElements = document.querySelectorAll('.lesson-element');

    lessonElements.forEach((element) => {
        element.style.opacity = 0;
        element.style.transform = "translateY(20px)";
    });

    lessonElements.forEach((element, index) => {
        setTimeout(() => {
            element.style.transition = "all 0.5s ease";
            element.style.opacity = 1;
            element.style.transform = "translateY(0)";
        }, index * 100);
    });

    // Add drag-and-drop functionality
    const sortableContainers = document.querySelectorAll('.sortable');

    sortableContainers.forEach((container) => {
        new Sortable(container, {
            animation: 150,
            onEnd: function (/**Event*/evt) {
                const itemEl = evt.item;  // dragged HTMLElement
                const newIndex = evt.newIndex;

                // Send AJAX request to update position
                fetch(`/update_position`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': document.querySelector('[name=csrf-token]').content
                    },
                    body: JSON.stringify({
                        id: itemEl.dataset.id,
                        new_position: newIndex
                    })
                });
            }
        });
    });


    lessonElements.forEach((element) => {
        element.style.opacity = 0;
        element.style.transform = "translateY(20px)";
    });

    lessonElements.forEach((element, index) => {
        setTimeout(() => {
            element.style.transition = "all 0.5s ease";
            element.style.opacity = 1;
            element.style.transform = "translateY(0)";
        }, index * 100);
    });
}

export { initLessons };