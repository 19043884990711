import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
const QcmComponent = ({ element, junior, current_user, qcmId }) => {
    const [qcmData, setQcmData] = useState([]);
    const [userAnswers, setUserAnswers] = useState({});
    const [qcmAnswer, setQcmAnswer] = useState(null);
    const [cooldown, setCooldown] = useState(false);
    const [message, setMessage] = useState('');
    const [isCorrect, setIsCorrect] = useState(false);

    useEffect(() => {
        if (element.content) {
            setQcmData(JSON.parse(element.content));
        }
        fetchQcmAnswer(element.id);
    }, [element]);

    const fetchQcmAnswer = async (lessonElementId) => {
        const response = await fetch(`/juniors/${junior.current_junior}/qcm_answers?user_id=${current_user}&lesson_element_id=${lessonElementId}`);
        const data = await response.json();
        if (data.length > 0) {
            const latestAnswer = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];
            setQcmAnswer(latestAnswer);
            if (latestAnswer.validation) {
                setMessage('Félicitation, vous avez correctement répondu à toutes les questions ! Vous avez donc déjà validé ce QCM.');
                setIsCorrect(true);
            } else {
                const lastAttemptTime = new Date(latestAnswer.created_at).getTime();
                const currentTime = new Date().getTime();
                if ((currentTime - lastAttemptTime) / 1000 / 60 < 2) {
                    setCooldown(true);
                    setMessage("Vous n'avez pas correctement répondu au QCM. Vous devez donc attendre 2 minutes avant de pouvoir refaire ce QCM.");
                    setIsCorrect(false);
                }
            }
        }
    };

    const handleAnswerChange = (questionIndex, answerIndex) => {
        setUserAnswers(prevAnswers => {
            const updatedAnswers = { ...prevAnswers };
            if (!updatedAnswers[questionIndex]) {
                updatedAnswers[questionIndex] = [];
            }
            if (updatedAnswers[questionIndex].includes(answerIndex)) {
                updatedAnswers[questionIndex] = updatedAnswers[questionIndex].filter(i => i !== answerIndex);
            } else {
                updatedAnswers[questionIndex].push(answerIndex);
            }
            return updatedAnswers;
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (cooldown || message === 'Vous avez déjà validé ce QCM.') return;

        const isCorrect = qcmData.every((question, index) => {
            const correctAnswers = question.answers.map((answer, i) => answer.correct ? i : null).filter(i => i !== null);
            return JSON.stringify(correctAnswers.sort()) === JSON.stringify(userAnswers[index]?.sort());
        });

        const response = await fetch(`/juniors/${junior.current_junior}/qcm_answers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({
                user_id: current_user,
                lesson_element_id: element.id,
                lesson_progress_id: null,
                content: JSON.stringify(userAnswers),
                validation: isCorrect
            })
        });

        if (response.ok) {
            fetchQcmAnswer(element.id);
            setMessage(isCorrect ? 'Vous avez correctement répondu au QCM !' : 'Vous avez fait des erreurs dans le QCM.');
            setIsCorrect(isCorrect);
        }
    };

    return (
        <div className="qcm-container">
            {message && <p className={`message ${isCorrect ? 'success' : 'error'}`}>{message}</p>}
            <form onSubmit={handleSubmit}>
                {qcmData.map((question, questionIndex) => (
                    <div key={questionIndex} className="question-block">
                        <p>{question.question}</p>
                        {question.answers.map((answer, answerIndex) => (
                            <div key={answerIndex} className="answer-block">
                                <input
                                    type="checkbox"
                                    id={`q${qcmId}-${questionIndex}-${answerIndex}`}
                                    name={`question${qcmId}-${questionIndex}`}
                                    value={answerIndex}
                                    onChange={() => handleAnswerChange(questionIndex, answerIndex)}
                                    checked={userAnswers[questionIndex]?.includes(answerIndex) || false}
                                />
                                <label htmlFor={`q${qcmId}-${questionIndex}-${answerIndex}`}>{answer.text}</label>
                            </div>
                        ))}
                    </div>
                ))}
                <button type="submit" disabled={message !== ''}>Soumettre</button>
            </form>
        </div>
    );
};


QcmComponent.propTypes = {
    element: PropTypes.object.isRequired,
    junior: PropTypes.object.isRequired,
    current_user: PropTypes.number.isRequired,
};

export default QcmComponent;
