const createGanttBar = (objectif, startDate, endDate, currentDate) => {
    const barContainer = document.createElement('div');
    barContainer.className = 'gantt-bar-container';

    const bar = document.createElement('div');
    bar.className = 'gantt-bar';
    bar.textContent = objectif.nom;

    const start = new Date(objectif.date_debut);
    const end = new Date(objectif.date_fin);
    const startOffset = (start - startDate) / (endDate - startDate) * 100;
    const duration = (end - start) / (endDate - startDate) * 100;

    bar.style.left = `${startOffset}%`;
    bar.style.width = `${duration}%`;

    const dateStartLabel = document.createElement('div');
    dateStartLabel.className = 'gantt-date-label start';
    dateStartLabel.textContent = objectif.date_debut;

    const dateEndLabel = document.createElement('div');
    dateEndLabel.className = 'gantt-date-label end';
    dateEndLabel.textContent = objectif.date_fin;

    bar.appendChild(dateStartLabel);
    bar.appendChild(dateEndLabel);

    // Vérifier si l'objectif est en cours
    if (currentDate >= start && currentDate <= end) {
        const currentDateMarker = document.createElement('div');
        currentDateMarker.className = 'current-date-marker';
        currentDateMarker.style.left = `${(currentDate - start) / (end - start) * 100}%`;
        bar.appendChild(currentDateMarker);
    }

    barContainer.appendChild(bar);

    return barContainer;
};

const initGantt = () => {
    const ganttContainer = document.getElementById('gantt_here');
    if (ganttContainer) {
        const ganttData = JSON.parse(ganttContainer.getAttribute('data-gantt'));
        ganttContainer.innerHTML = '';

        const startDate = new Date(Math.min(...ganttData.map(obj => new Date(obj.date_debut))));
        const endDate = new Date(Math.max(...ganttData.map(obj => new Date(obj.date_fin))));
        const currentDate = new Date();

        ganttData.forEach(actionObjectif => {
            const bar = createGanttBar(actionObjectif, startDate, endDate, currentDate);
            ganttContainer.appendChild(bar);
        });
    }
};

export { initGantt };
